import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import { externalLinkClicked } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import { useDispatch, useSelector } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesVideoResult } from 'styles/results/VideoResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import VideoPlaceholder from 'images/kpmg_placeholder.png'
import VideoOverlay from 'images/video_overlay.png'
import { getPreviewThumbnail } from 'utils/msgraphThumbnailPicture'
import AuthStore from 'store/Auth'
import { Store } from 'store'
import { connect } from 'react-redux'
import TooltipTitle from '../common/TooltipTitle'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadSPOBlob, getFileSize } from 'utils/o365'
import CircularProgress from '@mui/material/CircularProgress'
import prettyBytes from 'pretty-bytes'
import { useFilter } from 'utils/filters'
interface MediaCardProps {
  title: string
  subtitle: string
  image?: string
  siteId: string
  webId: string
  listId: string
  listItemId: string
  publishDate: string
  url: string
  size: string
  deviceSettings: IDeviceSetting
  index: number
  videoLink: string
  viewsLifeTime?: string | undefined
}

const handleVideoLinkClick = (
  url: string,
  openLinksInNewTab: boolean | undefined,
  isAuxClick: boolean,
  isCtrlKeyPressed: boolean
) => {
  if (openLinksInNewTab === true || isCtrlKeyPressed) {
    window.open(url)
  } else if (isAuxClick === false) {
    window.location.href = url
  }
}

type AllProps = ReturnType<typeof mapStateToProps> & MediaCardProps

function MediaCard(props: AllProps): JSX.Element {
  const classes = getStylesVideoResult()
  const intl = useIntl()
  const {
    title,
    image,
    publishDate,
    url,
    size,
    deviceSettings,
    aadInfo,
    siteId,
    webId,
    listId,
    listItemId,
    index,
    viewsLifeTime,
    videoLink
  } = props
  const dispatch = useDispatch()
  const [isVisited, setVisited] = useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const [pictureUrl, setPictureUrl] = useState('')
  const [resultSize, setResultSize] = useState(size)
  const [orderByFilterValue] = useFilter('orderBy')

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  const loadPicture = async () => {
    const newPictureURL = await getPreviewThumbnail(
      image,
      siteId,
      webId,
      listId,
      listItemId,
      deviceSettings,
      aadInfo
    )
    if (newPictureURL) setPictureUrl(newPictureURL)
    else if (image) setPictureUrl(image)
  }

  const loadVideoSize = async () => {
    const size = await getFileSize(url, aadInfo)
    setResultSize(size)
  }

  useEffect(() => {
    loadPicture()
    loadVideoSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDownload = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    downloadSPOBlob(aadInfo, url, '', title + '.mp4', setDownloadInProgress)
  }

  let _viewsLifeTime = null
  if (viewsLifeTime) {
    let iValue = parseInt(viewsLifeTime as string)
    _viewsLifeTime = iValue.toLocaleString()
  }

  return (
    <Card
      className={classes.card}
      onClick={(event: any) => {
        externalLinkClicked({
          title,
          url: videoLink ? videoLink : url,
          index: index
        })
        handleVideoLinkClick(
          videoLink ? videoLink : url,
          deviceSettings.openLinksInNewTab,
          false,
          event.ctrlKey
        )
        setIsVisited()
      }}
      {...(!deviceSettings.isMobile
        ? {
            onAuxClick: (event: any) => {
              externalLinkClicked({
                title,
                url: videoLink ? videoLink : url,
                index: index,
                wasRightClicked: event?.button === 2 ? true : false
              })
              handleVideoLinkClick(
                videoLink ? videoLink : url,
                deviceSettings.openLinksInNewTab,
                true,
                false
              )
              setIsVisited()
            }
          }
        : {})}
    >
      <CardActionArea
        href={videoLink ? videoLink : url} //keep ie11 aux menu
        onClick={(event: any) => {
          event.preventDefault()
        }}
        className={classes.link}
      >
        <CardMedia
          className={classes.media}
          sx={
            pictureUrl && pictureUrl !== ''
              ? {
                  backgroundImage: `url("${pictureUrl}"), url("${VideoPlaceholder}") !important`,
                  '&:hover': {
                    backgroundImage: `url("${VideoOverlay}"), url("${pictureUrl}"), url("${VideoPlaceholder}") !important`
                  }
                }
              : {
                  backgroundImage: `url("${VideoPlaceholder}") !important`,
                  '&:hover': {
                    backgroundImage: `url("${VideoOverlay}"), url("${VideoPlaceholder}") !important`
                  }
                }
          }
        >
          <Box className={classes.spacer} />
          <Box className={classes.spacer} />
        </CardMedia>
        <CardContent className={classes.content}>
          <Link
            tabIndex={-1}
            underline={'hover'}
            href={videoLink ? videoLink : url} //keep ie11 aux menu
            onClick={(event: any) => {
              event.preventDefault()
            }}
            className={classes.link}
          >
            <Typography
              gutterBottom
              variant="body1"
              component="h5"
              color="primary"
              className={`${classes.title} ${
                isVisited ? classes.isVisitedResult : ''
              }`}
            >
              <TooltipTitle title={title ? title : ''} singleLine={false} />
            </Typography>
          </Link>
          <Box className={classes.spacer} />
          {_viewsLifeTime && 'viewslifetime' === orderByFilterValue ? (
            <div>
              <Typography
                variant="caption"
                className={classes.subtitle}
                color="textSecondary"
                component="p"
              >
                {_viewsLifeTime}{' '}
                <FormattedMessage
                  id="breadcrumb_views"
                  defaultMessage="views"
                />
              </Typography>
            </div>
          ) : null}
          <div className={classes.footerTable}>
            <div className={classes.footerTableRow}>
              <div className={classes.footerTableCell}>
                <Typography
                  variant="caption"
                  className={classes.subtitle}
                  color="textSecondary"
                  component="p"
                >
                  {publishDate && publishDate !== ''
                    ? intl.formatDate(publishDate, dateFormatOptions)
                    : ''}
                </Typography>
              </div>
              <div className={classes.footerTableCell}>
                {!downloadInProgress && (
                  <DownloadIcon
                    tabIndex={0}
                    onClick={handleDownload}
                    onKeyDown={(event: React.KeyboardEvent<SVGSVGElement>) => {
                      if (event.key === 'Enter') {
                        handleDownload(event)
                      }
                    }}
                    className={classes.downloadIcon}
                  />
                )}
                {downloadInProgress && (
                  <CircularProgress
                    className={classes.loadingSpinner}
                    size={14}
                  />
                )}
              </div>
            </div>
            <div className={classes.footerTableRow}>
              <div className={classes.footerTableCell}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {resultSize && resultSize !== '0'
                    ? prettyBytes(Number(resultSize))
                    : ''}
                </Typography>
              </div>
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(MediaCard)
