import React, { Dispatch, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { connect } from 'react-redux'
import { Box, Fade, Grid } from '@mui/material'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsVideosStore from 'store/ResultsVideos'
import Video from '../results/VideoResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { getStylesResultsVideos } from 'styles/contents/resultpages/ResultsVideos'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import { useIntl } from 'react-intl'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from '../common/CognitiveMessage'

interface ResultsVideosMobileProps {
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  findConfiguration: IFindConfiguration
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsVideosMobileProps

function ResultsVideosMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    totalRowCount,
    lastRow,
    featuredResults,
    resultsCombined,
    deviceSettings,
    userSettings,
    findConfiguration,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const intl = useIntl()
  const resultClasses = getStylesResults()
  const classes = getStylesResultsVideos()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (
      !resultsCombined ||
      !resultsCombined.queryResults ||
      resultsCombined.queryResults.length < 1
    ) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.queryResults.length < 4
          ? resultsCombined.queryResults.length
          : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          items.push(
            <Grid item xs={6} sm={4}>
              <Video
                title={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].Title
                )}
                subtitle={'Intranet'}
                image={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].PictureThumbnailUrl
                )}
                siteId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].SiteID
                )}
                webId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].WebID
                )}
                listId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].ListID
                )}
                listItemId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].ListItemID
                )}
                publishDate={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].Date
                )}
                url={DOMPurify.sanitize(resultsCombined.queryResults[i].Path)}
                size={DOMPurify.sanitize(resultsCombined.queryResults[i].Size)}
                deviceSettings={deviceSettings}
                index={i}
                videoLink={
                  resultsCombined.queryResults[i].SPWebUrl &&
                  resultsCombined.queryResults[i].UniqueID
                    ? `${DOMPurify.sanitize(resultsCombined.queryResults[i].SPWebUrl)}/_layouts/15/viewer.aspx?sourcedoc=${encodeURIComponent(DOMPurify.sanitize(resultsCombined.queryResults[i].UniqueID))}`
                    : ''
                }
              />
            </Grid>
          )
        }
      } else {
        for (
          let i = firstResults;
          i < resultsCombined.queryResults.length;
          i++
        ) {
          items.push(
            <Grid item xs={6} sm={4}>
              <Video
                title={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].Title
                )}
                subtitle={'Intranet'}
                image={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].PictureThumbnailUrl
                )}
                siteId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].SiteID
                )}
                webId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].WebID
                )}
                listId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].ListID
                )}
                listItemId={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].ListItemID
                )}
                publishDate={DOMPurify.sanitize(
                  resultsCombined.queryResults[i].Date
                )}
                url={DOMPurify.sanitize(resultsCombined.queryResults[i].Path)}
                size={DOMPurify.sanitize(resultsCombined.queryResults[i].Size)}
                deviceSettings={deviceSettings}
                index={i}
                videoLink={
                  resultsCombined.queryResults[i].SPWebUrl &&
                  resultsCombined.queryResults[i].UniqueID
                    ? `${DOMPurify.sanitize(resultsCombined.queryResults[i].SPWebUrl)}/_layouts/15/viewer.aspx?sourcedoc=${encodeURIComponent(DOMPurify.sanitize(resultsCombined.queryResults[i].UniqueID))}`
                    : ''
                }
              />
            </Grid>
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsVideosMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={totalRowCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                findConfiguration.CognitiveSearchEnabled &&
                resultsCombined.synonymsApplied &&
                resultsCombined.synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={resultsCombined.synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && totalRowCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              <Grid
                container
                spacing={deviceSettings.renderMobile ? 1 : 3}
                className={classes.grid}
              >
                {firstItems}
                {restItems}
              </Grid>
              {totalRowCount != null && totalRowCount > 0 && (
                <Pagination
                  {...props}
                  totalRecords={totalRowCount}
                  pageLimit={21}
                  pageNeighbours={4}
                  loadMore={false}
                  lastRow={lastRow}
                />
              )}
            </Box>
          </Fade>
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={classes.placeholder}
        >
          <Box />
        </ReactPlaceholder>
        {/* Currently commented out, the datasource didn't have any left widgets Bug: 643328
            If commented in again the logic with firstitems is not right.
            Depending on the screen resolution the firstitems should have 2 or 3 elements, so no blank spaces occure.
            The additional added placeholder should be removed
        */}
        {/* {Number(currentPage) === 1 && (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )} */}
        {/* <ReactPlaceholder
          ready={hasResultsBeenFetched}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade in={hasResultsBeenFetched} timeout={600}>
            <Box>
              <Grid
                container
                spacing={deviceSettings.renderMobile ? 1 : 3}
                className={classes.grid}
              >
                {restItems}
              </Grid>
              {resultsCount != null && resultsCount > 0 && (
                <Pagination
                  {...props}
                  totalRecords={resultsCount}
                  pageLimit={21}
                  pageNeighbours={4}
                  currentPage={Number(currentPage)}
                  setCurrentPage={(currPage: number) => {
                    try {
                      setCurrentPage(currPage.toString(), { method: 'push' })
                    } catch (error) {
                      trackException(
                        'Error in setCurrentPage in ResultsVideosMobile.tsx',
                        error
                      )
                    }
                  }}
                />
              )}
            </Box>
          </Fade>
        </ReactPlaceholder> */}
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsVideosStore.selectors.getFeaturedResults(state),
    resultsCombined: ResultsVideosStore.selectors.getResultsCombined(state),
    totalRowCount: ResultsVideosStore.selectors.getTotalRowCount(state),
    lastRow: ResultsVideosStore.selectors.getLastRow(state),
    executionTime: ResultsVideosStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsVideosStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsVideosMobile)
