import React, { useState, Dispatch, useRef, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
//import pdfjsLib from 'pdfjs-dist/webpack';
import { withStyles } from '@mui/styles'
import {
  Typography,
  Link,
  Box,
  Grid,
  Button,
  Modal,
  Menu,
  MenuProps,
  MenuItem,
  SvgIcon,
  Fade,
  IconButton
} from '@mui/material'
import * as stringUtils from 'utils/string'
import {
  trackException,
  externalLinkClicked,
  trackFeaturedShown,
  IExternalLinkParams
} from 'utils/tracking'
import { getCurrentScope, removeQueryParam } from 'utils/queryParams'
import { generateUrlBreadcrumbs } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
//import 'pdfjs-dist/web/pdf_viewer.css';
import SaveIcon from '@mui/icons-material/Save'
import { getStylesTextResult } from 'styles/results/TextResult'
import { ISeoCheckerData, SEOCheckerTypes } from 'utils/seoChecker'
import { Theme } from '@mui/material/styles'
import {
  dataSourcesTabs,
  emptyGuid,
  imageOrVideoFileExtensions
} from 'constants/constants'
import SharePointShareDialog from '../common/SharePointShareDialog'
import TooltipTitle from '../common/TooltipTitle'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { getFileType } from 'utils/filetype'
import { CustomDescription } from '../common/CustomDescription'
import { downloadSPOBlob } from 'utils/o365'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import * as Config from 'config'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import AuthStore from 'store/Auth'
import mime from 'mime'
import TranslateIcon from '@mui/icons-material/Translate'
import { IResultTranslations } from 'components/models/Translation'
import TranslationDialog from '../common/TranslationDialog'
import CloseIcon from '@mui/icons-material/Close'
import { useIntl } from 'react-intl'

//const pdfjsViewer: any = require('pdfjs-dist/web/pdf_viewer');
interface TextResultProps {
  title: string
  body: string
  url: string
  featured: boolean
  showContextMenu: boolean
  featuredKeyword?: string
  index?: number
  displayUrl?: string | JSX.Element
  serverRedirectedURL?: string
  disableNoWrap?: boolean
  searchQuery?: string
  tabType?: string
  setSeoCheckerModal?: Dispatch<boolean>
  setSEOCheckerModalData?: Dispatch<ISeoCheckerData>
  deviceSettings: IDeviceSetting
  openInWeb?: boolean
  isLink?: boolean
  contentCategoryType?: string
  siteTitle?: string
  originalUrl?: string
  attachmentLinks?: Array<string>
  contentClass?: string
  listID?: string
  sPWebUrl?: string
  uniqueID?: string
  siteID?: string
  defaultEncodingURL?: string
  useImprovedSharingDialog?: boolean
  synonymsApplied?: ISynonymsApplied[]
  shortcutUrl?: string
  disableGoToFolder?: boolean
  disableSharing?: boolean
  timeStamp?: string
  additionalBreadcrumbText?: string | null
}

type AllProps = ReturnType<typeof mapStateToProps> & TextResultProps

function TextResult(props: AllProps): JSX.Element {
  const {
    title,
    body,
    url,
    featured,
    showContextMenu,
    featuredKeyword,
    index,
    displayUrl,
    disableNoWrap,
    searchQuery,
    tabType,
    setSeoCheckerModal,
    setSEOCheckerModalData,
    serverRedirectedURL,
    deviceSettings,
    openInWeb,
    isLink,
    contentCategoryType,
    siteTitle,
    originalUrl,
    attachmentLinks,
    contentClass,
    listID,
    sPWebUrl,
    uniqueID,
    siteID,
    defaultEncodingURL,
    useImprovedSharingDialog,
    synonymsApplied,
    shortcutUrl,
    disableGoToFolder,
    disableSharing,
    timeStamp,
    internalBroadcast,
    additionalBreadcrumbText,
    aadInfo,
    findConfiguration
  } = props
  const navigateFunction = useNavigate()
  const scope = getCurrentScope(false)
  const classes = getStylesTextResult()
  const intl = useIntl()
  const [translationResult, setTranslationResult] =
    useState<IResultTranslations>({
      values: [{ text: title }],
      to: 'en'
    })
  const [translationDialogOpen, setTranslationDialogOpen] = useState(false)
  const [showShareDialog, setShowShareDialog] = useState(false)

  const [showDocumentPreview, setShowDocumentPreview] = useState(false)
  const [isVisited, setVisited] = useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )

  const dispatch = useDispatch()

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  const [anchorElementMenu, setAnchorElementMenu] =
    useState<null | HTMLDivElement>(null)
  //const [menuExpanded, setMenuExpanded] = useState(false)

  const currentTab = KPMGFindGlobalVariables.getCurrentTab()

  let docTypeFlair: string | null = null
  let urlBreadcrumbs = ''
  const maxBreadCrumbLength = deviceSettings.isMobile ? 35 : 79
  React.useEffect(() => {
    if (featured) {
      trackFeaturedShown({
        featured,
        featuredKeyword,
        title,
        url,
        index
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (url && !tabType) {
    if (!contentCategoryType) {
      let urlToCheck = url

      // if not a link and multiple attachments, show the first attachment file type
      if (
        !isLink &&
        attachmentLinks &&
        attachmentLinks.length > 1 &&
        attachmentLinks[0]
      ) {
        urlToCheck = attachmentLinks[0]
      }

      const match = urlToCheck.match(new RegExp('\\.(\\w+)($|\\?)', 'g'))
      let fileType = match && match.length > 0 ? match[match.length - 1] : ''
      fileType = fileType.replace(/[?\\.]/g, '')

      // Check if some special chars are inside the filetype
      if (
        fileType &&
        fileType.toLowerCase() !== 'com' &&
        !fileType.match(/[^A-Za-z0-9-]+/g)
      ) {
        if (mime.getType(fileType)) {
          const mimeType: string = mime.getType(fileType) as string
          docTypeFlair = mime.getExtension(mimeType)
        } else {
          docTypeFlair = getFileType(fileType)
        }
      }
    } else {
      docTypeFlair = contentCategoryType
    }

    // Build URL Breadcrumbs
    let breadcrumbUrl = url
    if (originalUrl && originalUrl !== '') breadcrumbUrl = originalUrl
    urlBreadcrumbs = generateUrlBreadcrumbs(scope, breadcrumbUrl, featured)

    // Build breadcrumb in case is an OI Result
    if (siteTitle && siteTitle !== '') {
      const cleanedURL = breadcrumbUrl.replace(
        new RegExp('https://[a-zA-z1-9.-]+/[A-Za-z]ites/'),
        ''
      )
      if (cleanedURL.toLowerCase().indexOf('-oi-') >= 0) {
        const parts = cleanedURL.split('-')
        if (parts.length >= 2) {
          urlBreadcrumbs =
            parts[1].toUpperCase() + ' › ' + parts[0].toUpperCase()
          if (siteTitle !== '') {
            urlBreadcrumbs += ' › ' + siteTitle
          }
          let docLib = breadcrumbUrl.substr(0, breadcrumbUrl.lastIndexOf('/'))
          docLib = docLib.substr(docLib.lastIndexOf('/') + 1)
          if (
            docLib.length > 0 &&
            docLib.toLowerCase().indexOf('-oi-') === -1 &&
            docLib.toLowerCase() !== 'sites'
          ) {
            urlBreadcrumbs += ' › ' + docLib
          }
        }
      }
    }

    if (urlBreadcrumbs.length > maxBreadCrumbLength) {
      urlBreadcrumbs = stringUtils.truncate(urlBreadcrumbs, maxBreadCrumbLength)
    }
  } else if (tabType) {
    if (tabType === 'General') {
      urlBreadcrumbs = 'All'
    } else {
      urlBreadcrumbs = tabType
    }
  }

  /*
  let loadDoc: any;

  if (docTypeFlair === 'pdf') {
    loadDoc = () => {
      window.open(url, '_self')

      var container = document.getElementById(url);

      var eventBus = new pdfjsViewer.EventBus();

      // (Optionally) enable hyperlinks within PDF files.
      var pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus: eventBus,
      });

      // (Optionally) enable find controller.
      var pdfFindController = new pdfjsViewer.PDFFindController({
        eventBus: eventBus,
        linkService: pdfLinkService,
      });

      var pdfViewer = new pdfjsViewer.PDFViewer({
        container: container,
        eventBus: eventBus,
        linkService: pdfLinkService,
        findController: pdfFindController,
      });

      pdfLinkService.setViewer(pdfViewer);

      eventBus.on("pagesinit", function () {
        // pdfViewer.currentScaleValue = "page-width";
      });

      var loadingTask = pdfjsLib.getDocument(url);
      loadingTask.promise.then(function (pdfDocument: any) {
        pdfViewer.setDocument(pdfDocument);

        pdfLinkService.setDocument(pdfDocument, null);

        setShowDocumentPreview(true)
      });
    }
  } else {
    loadDoc = () => {
      window.open(`${url}?web=1`, '_self')
      // setShowDocumentPreview(true)
    }
  }*/

  // Define seo checker type and enabled or not
  let seoCheckerType: SEOCheckerTypes | null = null
  if (contentClass) {
    if (
      contentClass === 'STS_ListItem_WebPageLibrary' ||
      (contentClass === 'STS_ListItem_850' &&
        defaultEncodingURL &&
        defaultEncodingURL.indexOf('.aspx') >= 0)
    )
      seoCheckerType = SEOCheckerTypes.OnlinePage
    else if (!!docTypeFlair && docTypeFlair !== 'mp4') {
      const blockSeoChecker =
        imageOrVideoFileExtensions.indexOf(docTypeFlair.toLowerCase()) !== -1

      if (!blockSeoChecker) seoCheckerType = SEOCheckerTypes.OnlineFile
    } else if (contentClass === 'STS_Site')
      seoCheckerType = SEOCheckerTypes.OnlineWeb
  }

  const enableSeoChecker =
    seoCheckerType !== null &&
    !deviceSettings.renderMobile &&
    !!setSeoCheckerModal &&
    !!setSEOCheckerModalData

  const handleMenuClick = (event: any) => {
    setAnchorElementMenu(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorElementMenu(null)
  }

  const handleDownload = () => {
    setAnchorElementMenu(null)
    if (
      currentTab === dataSourcesTabs.source ||
      currentTab === dataSourcesTabs.knowledgeexchangeopen ||
      currentTab === dataSourcesTabs.knowledgeexchangecatalog ||
      currentTab === dataSourcesTabs.kpmgus
    ) {
      let downloadUrl = ''
      try {
        if (currentTab === dataSourcesTabs.source) {
          const parsedURL = new URL(url)
          if (parsedURL && parsedURL.searchParams) {
            const searchParams = parsedURL.searchParams
            if (searchParams) {
              downloadUrl =
                'https://' +
                Config.SOURCE_SERVICE_URL +
                '/download/attachments/' +
                searchParams.get('preview')?.split('/')[1] +
                '/' +
                title +
                '?version=1&modificationDate=' +
                timeStamp +
                '&api=v2&download=true'
            }
          }
        } else if (
          currentTab === dataSourcesTabs.knowledgeexchangeopen ||
          currentTab === dataSourcesTabs.knowledgeexchangecatalog
        ) {
          downloadUrl = url.split('?FileUrl=')[1]
        } else if (currentTab === dataSourcesTabs.kpmgus) {
          downloadUrl = url
        }
      } catch {}

      if (downloadUrl !== '') {
        // Create a tag and click it
        const a = document.createElement('a')
        a.href = downloadUrl
        if (currentTab !== dataSourcesTabs.kpmgus) {
          a.target = '_blank'
        }
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(downloadUrl)
      }
    } else {
      downloadSPOBlob(aadInfo, url, '', title + '.' + docTypeFlair)
    }
  }

  const handleGoToFolder = () => {
    setAnchorElementMenu(null)

    try {
      const folderUrl = url.substring(0, url.lastIndexOf('/') + 1)
      if (deviceSettings.openLinksInNewTab) {
        const a = document.createElement('a')
        a.href = folderUrl
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(folderUrl)
      } else {
        window.location.href = folderUrl
      }
    } catch (error) {
      trackException('Exception handling go to folder operation', error)
    }
  }

  const handleSharing = () => {
    setAnchorElementMenu(null)
    if (
      useImprovedSharingDialog &&
      title &&
      listID &&
      sPWebUrl &&
      defaultEncodingURL &&
      uniqueID &&
      uniqueID !== emptyGuid
    ) {
      setShowShareDialog(true)
    } else {
      window.location.href = `mailto:?body=Open result through following link:\n${encodeURI(
        encodeURI(url)
      )}&subject=Share search result`
    }
  }

  const handleTranslate = () => {
    setAnchorElementMenu(null)
    setTranslationDialogOpen(true)
  }

  //handle force open/close menu
  const menuButtonRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (
      internalBroadcast &&
      internalBroadcast.includes('openResultMenu_text_' + index) &&
      anchorElementMenu === null &&
      menuButtonRef &&
      menuButtonRef.current
    )
      setAnchorElementMenu(menuButtonRef.current)

    if (
      internalBroadcast &&
      internalBroadcast.includes('closeResultMenu_text_' + index) &&
      anchorElementMenu !== null
    )
      setAnchorElementMenu(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBroadcast])

  //close panel on unload component
  useEffect(() => {
    return () => {
      if (anchorElementMenu) setAnchorElementMenu(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const StyledMenu = withStyles({
    paper: {
      borderRadius: 0,
      boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.2)',
      '& ul': {
        padding: '0'
      }
    }
  })((menuProps: MenuProps) => (
    <Menu elevation={1} className={classes.menu} {...menuProps} />
  ))

  const StyledMenuItem = withStyles((theme: Theme) => ({
    root: {
      fontSize: '13px',
      minHeight: '30px'
    }
  }))(MenuItem)

  let textResultLink =
    shortcutUrl && docTypeFlair === 'url'
      ? shortcutUrl
      : serverRedirectedURL && serverRedirectedURL !== ''
        ? serverRedirectedURL
        : openInWeb
          ? url + '?web=1'
          : url

  if (docTypeFlair === 'mp4' && sPWebUrl && uniqueID) {
    textResultLink = `${sPWebUrl}/_layouts/15/viewer.aspx?sourcedoc=${encodeURIComponent(uniqueID)}`
  }

  return (
    <>
      {showDocumentPreview && (
        <Modal
          open={showDocumentPreview}
          keepMounted
          onClose={() => setShowDocumentPreview(false)}
          style={{
            overflow: 'scroll',
            padding: '15px'
          }}
        >
          <>
            <Box
              justifyContent="space-evenly"
              display="flex"
              paddingBottom="10px"
            >
              <Link
                href={url}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                >
                  Download
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => setShowDocumentPreview(false)}
              >
                Close
              </Button>
            </Box>
            <div id={url}>
              {docTypeFlair === 'pdf' && (
                <div id="viewer" className="pdfViewer"></div>
              )}
            </div>
          </>
        </Modal>
      )}
      <Grid container>
        <Grid item xs>
          <Box className={classes.result}>
            <div className={classes.urlWrapper}>
              {featured ? (
                <b className={classes.b}>
                  <FormattedMessage id="featured" defaultMessage="Featured" />
                  :&nbsp;
                </b>
              ) : (
                ''
              )}
              {featured && (
                <Typography
                  variant="body1"
                  display="inline"
                  className={
                    disableNoWrap
                      ? classes.urlBreadcrumbsWordWrap
                      : classes.urlBreadcrumbs
                  }
                >
                  {urlBreadcrumbs}
                  {additionalBreadcrumbText
                    ? ' ' + additionalBreadcrumbText
                    : null}
                </Typography>
              )}
              {!featured && !displayUrl && urlBreadcrumbs.length > 0 && (
                <Typography
                  variant="body1"
                  display="inline"
                  className={
                    disableNoWrap
                      ? classes.urlBreadcrumbsWordWrap
                      : classes.urlBreadcrumbs
                  }
                >
                  {urlBreadcrumbs}
                  {additionalBreadcrumbText
                    ? ' ' + additionalBreadcrumbText
                    : null}
                </Typography>
              )}
              {!featured && displayUrl && (
                <Typography
                  variant="body1"
                  display="inline"
                  className={
                    disableNoWrap
                      ? classes.urlBreadcrumbsWordWrap
                      : classes.urlBreadcrumbs
                  }
                >
                  {displayUrl}
                  {additionalBreadcrumbText
                    ? ' ' + additionalBreadcrumbText
                    : null}
                </Typography>
              )}
              {showContextMenu === true && (
                <>
                  <div
                    tabIndex={0}
                    ref={menuButtonRef}
                    data-tut={'reactour__resultmenubtn_text_' + index}
                    aria-controls="elementMenu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        handleMenuClick(event)
                      }
                    }}
                    className={classes.menuButton}
                  >
                    <SvgIcon>
                      <path d="M8 10l4 5 4-5z" />
                    </SvgIcon>
                  </div>
                  <StyledMenu
                    id="elementMenu"
                    anchorEl={anchorElementMenu}
                    open={Boolean(anchorElementMenu)}
                    onClose={handleMenuClose}
                    //fix width/height for tour by changing transform effect
                    {...(internalBroadcast &&
                    internalBroadcast.includes('openResultMenu_text_' + index)
                      ? { TransitionComponent: Fade }
                      : {})}
                    autoFocus={false}
                    disableAutoFocusItem={false}
                    MenuListProps={{
                      disableListWrap: true,
                      autoFocus: false
                    }}
                  >
                    <div
                      data-tut={'reactour__resultmenu_text_' + index}
                      tabIndex={-1}
                    >
                      {docTypeFlair && docTypeFlair.indexOf('htm') === -1 && (
                        <StyledMenuItem tabIndex={0} onClick={handleDownload}>
                          <FormattedMessage
                            id="context_menu_download"
                            defaultMessage="Download"
                          />
                        </StyledMenuItem>
                      )}
                      {docTypeFlair &&
                        !disableGoToFolder &&
                        currentTab !== 'source' && (
                          <StyledMenuItem
                            tabIndex={0}
                            onClick={handleGoToFolder}
                          >
                            <FormattedMessage
                              id="context_menu_go_to_folder"
                              defaultMessage="Go to folder"
                            />
                          </StyledMenuItem>
                        )}
                      {!disableSharing && (
                        <StyledMenuItem tabIndex={0} onClick={handleSharing}>
                          <FormattedMessage
                            id="context_menu_share"
                            defaultMessage="Share"
                          />
                        </StyledMenuItem>
                      )}

                      {enableSeoChecker && (
                        <StyledMenuItem
                          tabIndex={0}
                          onClick={function () {
                            setAnchorElementMenu(null)
                            if (setSEOCheckerModalData)
                              setSEOCheckerModalData({
                                url,
                                seoType: seoCheckerType,
                                uniqueId: uniqueID ? uniqueID : null,
                                siteId: siteID ? siteID : null
                              })
                            if (setSeoCheckerModal) setSeoCheckerModal(true)
                          }}
                        >
                          <FormattedMessage
                            id="context_menu_improve_ranking"
                            defaultMessage="Improve Ranking"
                          />
                        </StyledMenuItem>
                      )}
                      {findConfiguration.CognitiveSearchEnabled && (
                        <StyledMenuItem tabIndex={0} onClick={handleTranslate}>
                          <FormattedMessage
                            id="context_menu_translate"
                            defaultMessage="Translate"
                          />
                        </StyledMenuItem>
                      )}
                    </div>
                  </StyledMenu>
                  {docTypeFlair && (
                    <Typography
                      variant="caption"
                      display="inline"
                      color="secondary"
                      className={classes.docType}
                    >
                      {docTypeFlair}
                    </Typography>
                  )}
                </>
              )}
              {docTypeFlair && showContextMenu === false && (
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={`${classes.docType} ${classes.docTypeWithMargin}`}
                >
                  {docTypeFlair}
                </Typography>
              )}
              {isLink && (
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={`${classes.docType} ${classes.docTypeWithMargin}`}
                >
                  {'LINK'}
                </Typography>
              )}
              {!isLink && attachmentLinks && attachmentLinks.length > 1 && (
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={`${classes.multipleAttachments} ${classes.docTypeWithMargin}`}
                >
                  <FormattedMessage
                    id="has_multi_attachments_label"
                    defaultMessage="& others"
                  />
                </Typography>
              )}
            </div>
            <Link
              underline={'hover'}
              className={classes.link}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
              href={textResultLink}
              onClick={(event: any) => {
                /*if (docTypeFlair) {
                    event.preventDefault();
                    loadDoc()
                  }*/
                setIsVisited()
                removeQueryParam(navigateFunction, 'cntx')

                const linkClickParams: IExternalLinkParams = {
                  title,
                  url: textResultLink,
                  index,
                  featured: featured
                }

                if (featured) {
                  linkClickParams.featuredKeyword = featuredKeyword
                }

                externalLinkClicked(linkClickParams)
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      /*if (docTypeFlair) {
                      event.preventDefault();
                      loadDoc()
                    }*/
                      setIsVisited()
                      removeQueryParam(navigateFunction, 'cntx')

                      const linkClickParams: IExternalLinkParams = {
                        title,
                        url: textResultLink,
                        index,
                        featured: featured,
                        wasRightClicked: event?.button === 2 ? true : false
                      }

                      if (featured) {
                        linkClickParams.featuredKeyword = featuredKeyword
                      }

                      externalLinkClicked(linkClickParams)
                    }
                  }
                : {})}
            >
              <Typography
                variant="h5"
                color="primary"
                className={`${classes.title} ${
                  isVisited ? classes.isVisitedResult : ''
                }`}
                component="div"
              >
                <TooltipTitle title={title ? title : ''} singleLine={true} />
              </Typography>
            </Link>
            <Typography
              variant="body2"
              color="secondary"
              gutterBottom
              className={`${classes.body} txt-resultdescription-01`}
              component="div"
            >
              <CustomDescription
                descriptionValue={
                  body
                    ? highlightText(
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        prepareHighlightWords(
                          searchQuery,
                          body.replace(/(\r\n|\n|\r)/gm, ' '),
                          synonymsApplied
                        ),
                        true
                      )
                    : ''
                }
              />
            </Typography>
            {!translationResult.hasError &&
              !translationResult.hide &&
              translationResult.values.length > 0 &&
              translationResult.values[0].translatedText && (
                <div
                  style={{
                    border: '1px solid #dfe1e5',
                    paddingTop: '7px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    borderRadius: '4px',
                    position: 'relative'
                  }}
                >
                  <IconButton
                    style={{
                      position: 'absolute',
                      cursor: 'pointer',
                      right: '1px',
                      top: '1px',
                      width: '30px',
                      height: '30px'
                    }}
                    onClick={() => {
                      translationResult.hide = true
                      setTranslationResult({ ...translationResult })
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <TranslateIcon fontSize="small" />
                    <span style={{ marginLeft: '5px' }}>{`${intl.formatMessage({
                      id: 'translation_result_translate_into',
                      defaultMessage: 'Translation into'
                    })} ${
                      translationResult.supportedLanguage
                        ? intl.formatMessage({
                            id: translationResult.supportedLanguage
                              .translationId,
                            defaultMessage:
                              translationResult.supportedLanguage.name
                          })
                        : intl.formatMessage({
                            id: 'english',
                            defaultMessage: 'English'
                          })
                    }:`}</span>
                  </div>
                  <Typography
                    variant="h5"
                    color="primary"
                    className={`${classes.title} ${
                      isVisited ? classes.isVisitedResult : ''
                    }`}
                    component="div"
                  >
                    <TooltipTitle
                      title={translationResult.values[0].translatedText}
                      singleLine={true}
                    />
                  </Typography>
                  {translationResult.values.length > 1 &&
                    translationResult.values[1].translatedText && (
                      <Typography
                        variant="body2"
                        color="secondary"
                        gutterBottom
                        className={`${classes.body} txt-resultdescription-01`}
                        component="div"
                      >
                        <CustomDescription
                          descriptionValue={
                            translationResult.values[1].translatedText
                          }
                        />
                      </Typography>
                    )}
                </div>
              )}
          </Box>
        </Grid>
      </Grid>

      {showShareDialog &&
        listID &&
        sPWebUrl &&
        defaultEncodingURL &&
        uniqueID && (
          <SharePointShareDialog
            isOpen={showShareDialog}
            closeDialog={setShowShareDialog}
            title={title}
            listId={listID}
            spWebUrl={sPWebUrl}
            defaultEncodingUrl={defaultEncodingURL}
            uniqueId={uniqueID}
          />
        )}
      {translationDialogOpen && (
        <TranslationDialog
          isOpen={translationDialogOpen}
          closeDialog={setTranslationDialogOpen}
          body={body}
          aadInfo={aadInfo}
          translationResult={translationResult}
          setTranslationResult={setTranslationResult}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    internalBroadcast: SettingsStore.selectors.getInternalBroadcast(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(TextResult)
