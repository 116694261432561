import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import {
  IOneIntranetResultQuery,
  prepareFilters,
  removeStopWordsFromQuery,
  videosDefaultquery
} from 'utils/oneIntranet'
import { stripHtml } from 'utils/string'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import AuthStore from 'store/Auth'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import SettingsStore from 'store/Settings'
import { IVideoResult } from 'components/models/VideoResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export enum ResultsVideosActionTypes {
  FETCH_REQUEST = 'resultsVideos/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsVideos/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsVideos/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsVideos/FETCH_FAILURE',
  INITIALIZE_RESULTS_VIDEOS = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsVideosActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsVideosActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsVideosActionTypes> {
  payload: {
    response: {
      queryResults: IVideoResult[]
      synonymsApplied: ISynonymsApplied[]
      totalRowCount: number
      lastRow: number
      hasError: boolean
      executionTime: number
    }
    featuredResults: FeaturedResult[]
    currentPage: number
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsVideosActionTypes> {
  payload: {
    response: {
      queryResults: IVideoResult[]
      synonymsApplied: ISynonymsApplied[]
      lastRow: number
      hasError: boolean
    }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsVideosActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IVideoResult[]
    synonymsApplied: ISynonymsApplied[]
    totalRowCount: number
    lastRow: number
    hasError: boolean
    executionTime: number
  },
  featuredResults: FeaturedResult[],
  currentPage: number,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsVideosActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, currentPage },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: {
    queryResults: IVideoResult[]
    synonymsApplied: ISynonymsApplied[]
    lastRow: number
    hasError: boolean
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsVideosActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsVideosActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsVideos = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('videos')

    dispatch(ResultMetaDataStore.actions.fetchRequest('videos', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error(
          'Authentication: Cannot renew Enterprise Search authentication token'
        )
      }

      let spoToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        if (spoToken === '') {
          throw new Error(
            'Authentication: Cannot renew SPO authentication token'
          )
        }
      }

      const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postsearchoi`

      const requestBody: IOneIntranetResultQuery = Object.assign(
        {},
        videosDefaultquery
      )

      const detectLanguageApiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/detectlanguage?searchQuery=${encodeURIComponent(searchQuery)}`

      const useCognitiveSearch =
        SettingsStore.selectors.getUseCognitiveSearch(getState())

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
        requestBody.cognitiveEnabled = true
      }

      requestBody.querytext = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      const bodyObject = prepareFilters(
        requestBody,
        filters,
        (currentPage || 1) * 21 - 21,
        userSettings,
        'videos',
        useCognitiveSearch
      )

      // Enable Feature Result fetch only on page 1
      bodyObject.processFeaturedResults =
        (currentPage || 1) === 1 ? true : false

      bodyObject.documentType = 'Video'

      const body = JSON.stringify(bodyObject)

      const hasError = Selectors.getHasOIError(getState())

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${spoToken}`,
            'Authorization-OnPrem': `${esToken}`
          },
          body: body
        },
        hasError,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'videos',
        searchQuery
      )

      let results
      if (!response || response.hasError || !response.responseJSON) {
        let hasOIError = false
        if (
          response.errorResponse &&
          (response.errorResponse.responseCode === 400 ||
            response.errorResponse.responseCode === 500) &&
          (currentPage || 1) === 1 &&
          !response.errorResponse.internalError
        ) {
          hasOIError = true
        }

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'videos',
            response.errorResponse,
            undefined,
            actionMetaData,
            hasOIError
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      let t1 = performance.now()
      if (response.ESTimeInQueue) {
        try {
          t1 = t1 - response.ESTimeInQueue
        } catch {}
      }

      const executionTime = t1 - t0

      const r: any[] = []

      results.QueryResults.map((res: any) => {
        let encodedTitle = encodeURIComponent(res.Title.trim())
        encodedTitle = encodedTitle
          .split('%E2')
          .join('')
          .split('%A0')
          .join('')
          .split('%80')
          .join('')
          .split('%8D')
          .join('')
        if (encodedTitle !== '') {
          r.push(res)
        }
        return false
      })

      results.QueryResults = r

      if (results && 'QueryResults' in results) {
        const videoResults: IVideoResult[] = []

        results.QueryResults.forEach((i: any) => {
          const videoResult: IVideoResult = {
            Title: i.Title ? stripHtml(i.Title) : '',
            Path: i.DefaultEncodingURL
              ? i.DefaultEncodingURL
              : i.Path
                ? i.Path
                : '',
            PictureThumbnailUrl: i.PictureThumbnailUrl
              ? i.PictureThumbnailUrl
              : '',
            SiteID: i.SiteID ? i.SiteID : '',
            WebID: i.WebID ? i.WebID : '',
            ListID: i.ListID ? i.ListID : '',
            ListItemID: i.ListItemID ? i.ListItemID : '',
            Date: i.Write ? i.Write : '',
            Size: i.Size ? i.Size : '0',
            SPWebUrl: i.SPWebUrl ? i.SPWebUrl : '',
            UniqueID: i.UniqueID ? i.UniqueID : '',
            ViewsLifeTime: i.ViewsLifeTime ? i.ViewsLifeTime : undefined
          }

          videoResults.push(videoResult)
        })

        const totalRowCount: number = results.TotalRowCount

        const lastRow = results.LastRow

        const hasError = results.hasError

        const synonymsApplied = results.SynonymsApplied

        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: videoResults,
                synonymsApplied,
                lastRow,
                hasError
              },
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [
                  ...resultsCombined.queryResults,
                  ...videoResults
                ],
                synonymsApplied: [
                  ...resultsCombined.synonymsApplied,
                  ...synonymsApplied
                ],
                lastRow: lastRow,
                hasError: hasError
              },
              actionMetaData
            )
          )
        }
        dispatch(
          fetchSuccess(
            {
              queryResults: videoResults,
              synonymsApplied,
              totalRowCount,
              lastRow,
              hasError,
              executionTime
            },
            featuredResults,
            currentPage || 1,
            actionMetaData
          )
        )

        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'videos',
            {
              executionTime: executionTime,
              resultsCount: totalRowCount
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'videos',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const triggerVideoDataSourceQuery = (
  searchQuery: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('videos')

    dispatch(ResultMetaDataStore.actions.fetchRequest('videos', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    return
  }
}

export type ResultsVideosActions = IFetchRequest | IFetchFailure | IFetchFailure
