import {
  buildCityRefinerForPeople,
  buildCountryRefinerForPeople,
  buildDepartmentRefinerForPeople,
  buildFilesRefinerForOffice365,
  buildJobTitleRefinerForPeople,
  buildRefinerForKnowledgeExchange,
  buildTypeRefinerForSource
} from 'utils/filtersmultiselect'

/* OPTIONS INTRANET */
export const portalOptionsIntranet: Filter[] = [
  {
    name: 'All Portals',
    key: '',
    value: 'all',
    image: 'united-nations',
    isDefaultFilter: true
  },
  {
    name: 'Engagement Information',
    key: 'EI',
    value: 'ddfb2bbb-34a2-4ff5-93cb-0c890bd0185c',
    image: 'united-nations'
  },
  {
    name: 'Global Home',
    key: 'GHO',
    value: 'ddfb2bbb-34a2-4ff5-93cb-0c890bd0185c',
    image: 'united-nations'
  },
  {
    name: 'Global Thought Leadership',
    key: 'GTL',
    value: 'thoughtleadership',
    image: 'united-nations'
  },
  {
    name: 'Albania',
    key: 'AL',
    value: '87a525ce-8c22-4ace-a4b3-c49878fafde9',
    image: 'albania'
  },
  {
    name: 'Angola',
    key: 'AO',
    value: '145dbec5-66bf-4999-bad8-85ad03486a17',
    image: 'angola'
  },
  {
    name: 'Argentina',
    key: 'AR',
    value: 'e53afca3-62eb-4679-91c3-f9a14723f536',
    image: 'argentina'
  },
  {
    name: 'Australia',
    key: 'AU',
    value: '4ddd7f1b-6b2b-4d6a-bf40-6f675b9ad453',
    image: 'australia'
  },
  {
    name: 'Austria',
    key: 'AT',
    value: 'c11171f4-1ebc-48b0-b040-45fee93f322d',
    image: 'austria'
  },
  {
    name: 'Bahrain',
    key: 'BH',
    value: 'df376500-bb8a-47d2-a8f9-3a6a34775bb9',
    image: 'bahrain'
  },
  {
    name: 'Belgium',
    key: 'BE',
    value: '51366967-6cb0-4294-aacd-1caf4440f967',
    image: 'belgium'
  },
  {
    name: 'Bolivia',
    key: 'BO',
    value: 'fd2305a3-8de9-49d0-88e9-74d29d69f608',
    image: 'bolivia'
  },
  {
    name: 'Brazil',
    key: 'BR',
    value: '13eb6e4c-d496-4c0d-a912-285e933ead15',
    image: 'brazil'
  },
  {
    name: 'Bulgaria',
    key: 'BG',
    value: '9e84298f-e6d2-44d7-b043-f735a3661858',
    image: 'bulgaria'
  },
  {
    name: 'Canada',
    key: 'CA',
    value: '4288d5ea-2ff4-464a-8eac-e882b04c4b47',
    image: 'canada'
  },
  {
    name: 'Caspian',
    key: 'KZ',
    value: '4288d5ea-2ff4-464a-8eac-e882b04c4b47',
    image: 'united-nations'
  },
  {
    name: 'Central and Eastern Europe',
    key: 'CEE',
    value: '9b2fc0bd-e3ea-475e-a591-6f6adff56fdd',
    image: 'european-union'
  },
  {
    name: 'Chile',
    key: 'CL',
    value: '1a9f53e3-1609-4db2-8ad2-d451c0ddd91e',
    image: 'chile'
  },
  {
    name: 'China',
    key: 'CN',
    value: 'e5b28c40-797a-4ac5-b7f7-fcb7a59a0f9d',
    image: 'china'
  },
  {
    name: 'Colombia',
    key: 'CO',
    value: '12b52d60-54fa-4e70-a873-20ad0753b49b',
    image: 'colombia'
  },
  {
    name: 'Costa Rica',
    key: 'CR',
    value: '1938d923-de8a-4ed7-bb81-52b3f5bfdc2d',
    image: 'costa-rica'
  },
  {
    name: 'Croatia',
    key: 'HR',
    value: 'cd3228f2-da05-4786-8165-67951d8c598c',
    image: 'croatia'
  },
  {
    name: 'Cyprus',
    key: 'CY',
    value: '4837f5a0-5e79-4be5-a562-32f84769270a',
    image: 'cyprus'
  },
  {
    name: 'Czech Republic',
    key: 'CZ',
    value: 'c9ebbd07-52ad-45bc-bc57-2bb53e65d428',
    image: 'czech-republic'
  },
  {
    name: 'Denmark',
    key: 'DK',
    value: '1eb902eb-c960-41df-8188-8ba6ba1869a9',
    image: 'denmark'
  },
  {
    name: 'Denmark Acor',
    key: 'DKA',
    value: '2a40405e-7370-4af0-8d12-cf39e3b3a767',
    image: 'denmark'
  },
  {
    name: 'Dominican Republic',
    key: 'DO',
    value: 'bed4e55a-e9a5-4a34-beb9-a749225c0bec',
    image: 'dominican-republic'
  },
  {
    name: 'East Africa',
    key: 'EAF',
    value: '5e6fd773-b548-4f48-b3c1-aabfe82a0474',
    image: 'united-nations'
  },
  {
    name: 'Ecuador',
    key: 'EC',
    value: '5037b6bf-02f5-41e5-bf8b-123e0425c39f',
    image: 'ecuador'
  },
  {
    name: 'El Salvador',
    key: 'SV',
    value: 'f5899b5f-ad85-406d-b3a9-eb3c43311289',
    image: 'salvador'
  },
  {
    name: 'Finland',
    key: 'FI',
    value: '65ccd544-90a7-49d3-9235-73abe05ef381',
    image: 'finland'
  },
  {
    name: 'France',
    key: 'FR',
    value: 'e0a95767-5fff-4cb7-bfb7-3a4bbdb52d77',
    image: 'france'
  },
  {
    name: 'Ghana',
    key: 'GH',
    value: '78307b6e-49a9-4ed5-88c6-ae09b20fc5d7',
    image: 'ghana'
  },
  {
    name: 'Germany',
    key: 'DE',
    value: 'a22d18c2-d0c2-4663-b745-ed3938b58aa1',
    image: 'germany'
  },
  {
    name: 'Greece',
    key: 'GR',
    value: 'c84a68d2-9486-4b83-8834-37b8f4610fb9',
    image: 'greece'
  },
  {
    name: 'Guatemala',
    key: 'GT',
    value: '5c5224e5-6d52-4143-9d78-5c8dd67d48e3',
    image: 'guatemala'
  },
  {
    name: 'Honduras',
    key: 'HN',
    value: '931de715-ffd9-4655-98dc-fa6ade9f39ed',
    image: 'honduras'
  },
  {
    name: 'Hungary',
    key: 'HU',
    value: '338ca37c-8d61-4dfe-a229-ea05288da8a3',
    image: 'hungary'
  },
  {
    name: 'Iceland',
    key: 'IS',
    value: '80fb0b72-0aea-4e54-b18f-d8aedafdcdd4',
    image: 'iceland'
  },
  {
    name: 'India',
    key: 'IN',
    value: '8ea2b5f1-71ea-446e-8a4e-e3f040abf4c0',
    image: 'india'
  },
  {
    name: 'Indonesia',
    key: 'ID',
    value: '79112348-0baf-4088-9870-2c985e46f870',
    image: 'indonesia'
  },
  {
    name: 'Ireland (Eolas)',
    key: 'IE',
    value: '92ea38e8-91ce-4d4a-9ea5-9717daabf94f',
    image: 'ireland'
  },
  {
    name: 'Israel',
    key: 'IL',
    value: 'ef02376e-af70-4322-8f34-ef9ed1f8f1cd',
    image: 'israel'
  },
  {
    name: 'Japan',
    key: 'JP',
    value: '3dd7242e-076f-4c23-b7cd-83567c51de31',
    image: 'japan'
  },
  {
    name: 'Korea',
    key: 'KR',
    value: 'ec240453-c76a-413f-ac94-c066952c469e',
    image: 'south-korea'
  },
  {
    name: 'KPMG Islands Group',
    key: 'KIG',
    value: '8bd652ab-2059-4bb2-9833-bd06ac1d2a3b',
    image: 'united-nations'
  },
  {
    name: 'Kuwait',
    key: 'KW',
    value: '9db39bcc-3db9-4121-a183-a4d1e1bf0117',
    image: 'kuwait'
  },
  {
    name: 'Luxembourg',
    key: 'LU',
    value: 'a0cd6a88-0c24-452a-856c-fae6f8ae8065',
    image: 'luxembourg'
  },
  {
    name: 'Malaysia',
    key: 'MY',
    value: 'cc43237b-76dd-49ed-bfd4-0ab2de4a1234',
    image: 'malaysia'
  },
  {
    name: 'Mauritius',
    key: 'MU',
    value: '7f133e1a-cd26-456a-a559-7e064f58f34f',
    image: 'mauritius'
  },
  {
    name: 'Mexico',
    key: 'MX',
    value: 'd60a0b71-1281-4546-8e45-04533b6713dc',
    image: 'mexico'
  },
  {
    name: 'Netherlands',
    key: 'NL',
    value: '76ce3770-1789-4b18-9af0-46d74f13c41c',
    image: 'netherlands'
  },
  {
    name: 'New Zealand',
    key: 'NZ',
    value: 'bf6d77de-fdc5-4eaa-9fb9-361850f5a9d7',
    image: 'new-zealand'
  },
  {
    name: 'Nicaragua',
    key: 'NI',
    value: '3249cf21-6aba-4550-90fc-cc1014c6f5ba',
    image: 'nicaragua'
  },
  {
    name: 'Nigeria',
    key: 'NG',
    value: '8830540f-4457-446f-b582-1c905225c7a9',
    image: 'nigeria'
  },
  {
    name: 'North Macedonia',
    key: 'MK',
    value: '80c06032-38b8-46bf-b21b-ca51ac53f50c',
    image: 'republic-of-macedonia'
  },
  {
    name: 'Norway',
    key: 'NO',
    value: 'b9ca3a5c-6ddd-492a-a93e-39046f6d2eb2',
    image: 'norway'
  },
  {
    name: 'Nordics',
    key: 'NDC',
    value: '0dbdb6c5-67d8-4144-a770-11c40669d0b5',
    image: 'united-nations'
  },
  {
    name: 'Pakistan',
    key: 'PK',
    value: '461e49c4-eea7-41e2-a58f-1395dd5efcf6',
    image: 'pakistan'
  },
  {
    name: 'Panama',
    key: 'PA',
    value: '678abcae-1e5b-4d86-a225-e9fc635b9b25',
    image: 'panama'
  },
  {
    name: 'Peru',
    key: 'PE',
    value: '48a74d22-49be-469b-b899-5a67cd8d3c39',
    image: 'peru'
  },
  {
    name: 'Philippines',
    key: 'PH',
    value: '56a85962-9783-41b8-bdc0-76b39ef3a52a',
    image: 'philippines'
  },
  {
    name: 'Poland',
    key: 'PL',
    value: 'a2b88a2f-8ed6-415e-b0c1-510cd5c3c2a2',
    image: 'republic-of-poland'
  },
  {
    name: 'Portugal',
    key: 'PT',
    value: '158b22e9-fdb3-4cb8-a6ac-279b9286e322',
    image: 'portugal'
  },
  {
    name: 'Qatar',
    key: 'QA',
    value: '3952eab3-0533-4eef-a7d0-2195b9431540',
    image: 'qatar'
  },
  {
    name: 'Romania',
    key: 'RO',
    value: '4b231d7f-208e-4d0f-835a-414800cd34e4',
    image: 'romania'
  },
  {
    name: 'Saudi Arabia',
    key: 'SA',
    value: 'cfd40ff9-8483-499e-b1e9-c78aadb03fa6',
    image: 'saudi-arabia'
  },
  {
    name: 'Serbia & Montenegro',
    key: 'RS',
    value: 'e7f16160-97b1-4125-a676-a96b2854d9a2',
    image: 'serbia'
  },
  {
    name: 'Singapore',
    key: 'SG',
    value: '1980a83d-4f77-4e28-8ca3-37f4b15a525e',
    image: 'singapore'
  },
  {
    name: 'Slovakia',
    key: 'SK',
    value: '0c5dfb4b-6092-4b74-bf40-981001cb5bb3',
    image: 'slovakia'
  },
  {
    name: 'Slovenia',
    key: 'SI',
    value: 'e8078088-d291-4628-9d9f-4c6f4314b52c',
    image: 'slovenia'
  },
  {
    name: 'South Africa',
    key: 'ZA',
    value: '602b0ce6-63b4-4e1f-811f-fcaa9906010d',
    image: 'south-africa'
  },
  {
    name: 'Spain',
    key: 'ES',
    value: '037db0d1-d550-4c5b-8d98-2ae7b203685f',
    image: 'spain'
  },
  {
    name: 'Sweden',
    key: 'SE',
    value: '3e694c01-223d-4e60-b6aa-544181b40058',
    image: 'sweden'
  },
  {
    name: 'Switzerland',
    key: 'CH',
    value: '514670da-9814-49a6-9ca5-741bf9543ea9',
    image: 'switzerland'
  },
  {
    name: 'Taiwan',
    key: 'TW',
    value: 'b48902d3-94a3-4efe-851d-76294b167562',
    image: 'taiwan'
  },
  {
    name: 'Thailand',
    key: 'TH',
    value: '1a2e8eed-9548-42ed-9910-315864f5dd69',
    image: 'thailand'
  },
  {
    name: 'Turkey',
    key: 'TR',
    value: '25dc361d-8c68-42f9-a1a2-9d714bf0a739',
    image: 'turkey'
  },
  {
    name: 'United Arab Emirates',
    key: 'AE',
    value: '7fcca41c-f8f5-4d90-a0a5-7d719ae00358',
    image: 'united-arab-emirates'
  },
  {
    name: 'United Kingdom',
    key: 'GB',
    value: '0558044f-5e1d-4d27-9890-7ca5976944c7',
    image: 'united-kingdom'
  },
  {
    name: 'United States',
    key: 'US',
    value: '2e88e790-a955-4a42-b0ec-4f473f7501dd',
    image: 'united-states-of-america'
  },
  {
    name: 'Uruguay',
    key: 'UY',
    value: '229bab64-1d74-43cd-8a23-91d2914526ce',
    image: 'uruguay'
  },
  {
    name: 'Venezuela',
    key: 'VE',
    value: 'cb3afc79-e1c4-4134-9758-30d2d8d18c60',
    image: 'venezuela'
  },
  {
    name: 'Vietnam',
    key: 'VN',
    value: '02d95745-9351-4df6-9e6e-b534ca7c4ce2',
    image: 'vietnam'
  },
  {
    name: 'ESG',
    key: 'EG',
    value: 'https://spo-global.kpmg.com/sites/GO-OI-BUS-GCM-KPMGImpact',
    image: 'united-nations'
  },
  {
    name: 'Global Clients and Markets',
    key: 'GM',
    value: 'https://spo-global.kpmg.com/sites/GO-OI-BUS-GCM',
    image: 'united-nations'
  },
  {
    name: 'KPMG Facts & Figures',
    key: 'KF',
    value: 'https://spo-global.kpmg.com/sites/GO-OI-INF-FF',
    image: 'united-nations'
  },
  {
    name: 'Global Corporate Development',
    key: 'GD',
    value: 'https://spo-global.kpmg.com/sites/GO-OI-BUS-GCD',
    image: 'united-nations'
  },
  {
    name: 'Global Quality & Risk Management',
    key: 'GQ',
    value: 'https://spo-global.kpmg.com/sites/go-oi-bus-GQRM',
    image: 'united-nations'
  },
  {
    name: 'KPMG Delivery Network (KDN)',
    key: 'KN',
    value: 'https://spo-global.kpmg.com/sites/GO-OI-BUS-KDN',
    image: 'united-nations'
  }
]

const documentOptionsIntranet: Filter[] = [
  {
    name: 'Powerpoint',
    key: 'PPT',
    value: 'PowerPoint',
    image: '',
    fileTypeIcon: 'pptx'
  },
  {
    name: 'Word',
    key: 'DOC',
    value: 'Word',
    image: '',
    fileTypeIcon: 'docx'
  },
  {
    name: 'Excel',
    key: 'XLS',
    value: 'Excel',
    image: '',
    fileTypeIcon: 'xlsx'
  },
  {
    name: 'OneNote',
    key: 'onenote',
    value: 'OneNote',
    image: '',
    fileTypeIcon: 'one'
  },
  {
    name: 'Loop',
    key: 'loop',
    value: 'Loop',
    image: '',
    fileTypeIcon: 'loop'
  },
  { name: 'PDF', key: 'PDF', value: 'PDF', image: '', fileTypeIcon: 'pdf' },
  {
    name: 'Photo',
    key: 'photo',
    value: 'Photo',
    image: '',
    fileTypeIcon: 'jpg'
  },
  {
    name: 'Video',
    key: 'video',
    value: 'Video',
    image: '',
    fileTypeIcon: 'mp4'
  },
  {
    name: 'Page',
    key: 'page',
    value: 'WebPage',
    image: '',
    fileTypeIcon: 'aspx'
  },
  {
    name: 'Email',
    key: 'Email',
    value: 'Email',
    image: '',
    fileTypeIcon: 'eml'
  },
  {
    name: 'Microsoft Publisher',
    key: 'MicrosoftPublisher',
    value: 'MicrosoftPublisher',
    image: '',
    fileTypeIcon: 'pub'
  },
  {
    name: 'Microsoft XPS',
    key: 'MicrosoftXPS',
    value: 'MicrosoftXPS',
    image: '',
    fileTypeIcon: 'xps'
  },
  {
    name: 'Open Document',
    key: 'OpenDocument',
    value: 'OpenDocument',
    image: '',
    fileTypeIcon: 'odt'
  },
  {
    name: 'Rich Text',
    key: 'RichText',
    value: 'RichText',
    image: '',
    fileTypeIcon: 'rtf'
  },
  {
    name: 'Text',
    key: 'TextOnly',
    value: 'Text',
    image: '',
    fileTypeIcon: 'txt'
  },
  {
    name: 'Url',
    key: 'Url',
    value: 'Url',
    image: '',
    fileTypeIcon: 'url'
  },
  {
    name: 'Visio',
    key: 'Visio',
    value: 'Visio',
    image: '',
    fileTypeIcon: 'vdw'
  },
  {
    name: 'XML',
    key: 'Xml',
    value: 'Xml',
    image: '',
    fileTypeIcon: 'xml'
  },
  {
    name: 'Zip',
    key: 'Zip',
    value: 'Zip',
    image: '',
    fileTypeIcon: 'zip'
  }
]

// https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
const languageOptionsIntranet: Filter[] = [
  {
    name: 'All Languages',
    key: 'all',
    value: 'all',
    image: 'united-nations',
    isDefaultFilter: true
  },
  { name: 'Afrikaans', key: 'af', value: 'af', image: 'south-africa' },
  { name: 'Albanian', key: 'sq', value: 'sq', image: 'albania' },
  { name: 'Arabic', key: 'ar', value: 'ar', image: 'united-arab-emirates' },
  { name: 'Armenian', key: 'hy', value: 'hy', image: 'armenia' },
  { name: 'Azerbaijani', key: 'az', value: 'az', image: 'azerbaijan' },
  { name: 'Basque', key: 'eu', value: 'eu', image: 'basque-country' },
  { name: 'Bengali (Bangla)', key: 'bn', value: 'bn', image: 'india' },
  { name: 'Bulgarian', key: 'bg', value: 'bg', image: 'bulgaria' },
  { name: 'Catalan', key: 'ca', value: 'ca', image: 'spain' },
  { name: 'Chinese (Simplified)', key: 'zhs', value: 'zh-tw', image: 'china' },
  { name: 'Chinese (Traditional)', key: 'zht', value: 'zh-cn', image: 'china' },
  { name: 'Croatian', key: 'hr', value: 'hr', image: 'croatia' },
  { name: 'Czech', key: 'cs', value: 'cs', image: 'czech-republic' },
  { name: 'Danish', key: 'da', value: 'da', image: 'denmark' },
  { name: 'Dutch', key: 'nl', value: 'nl', image: 'netherlands' },
  { name: 'English', key: 'en', value: 'en', image: 'united-kingdom' },
  { name: 'Estonian', key: 'et', value: 'et', image: 'estonia' },
  { name: 'Finnish', key: 'fi', value: 'fi', image: 'finland' },
  { name: 'French', key: 'fr', value: 'fr', image: 'france' },
  { name: 'Galician', key: 'gl', value: 'gl', image: 'spain' },
  { name: 'Georgian', key: 'ka', value: 'ka', image: 'georgia' },
  { name: 'German', key: 'de', value: 'de', image: 'germany' },
  { name: 'Greek', key: 'el', value: 'el', image: 'greece' },
  { name: 'Gujarati', key: 'gu', value: 'gu', image: 'india' },
  { name: 'Hebrew', key: 'he', value: 'he', image: 'israel' },
  { name: 'Hindi', key: 'hi', value: 'hi', image: 'india' },
  { name: 'Hungarian', key: 'hu', value: 'hu', image: 'hungary' },
  { name: 'Icelandic', key: 'is', value: 'is', image: 'iceland' },
  { name: 'Indonesian', key: 'id', value: 'id', image: 'indonesia' },
  { name: 'Italian', key: 'it', value: 'it', image: 'italy' },
  { name: 'Japanese', key: 'ja', value: 'ja', image: 'japan' },
  { name: 'Kannada', key: 'kn', value: 'kn', image: 'india' },
  { name: 'Kazakh', key: 'kk', value: 'kk', image: 'kazakhstan' },
  { name: 'Korean', key: 'ko', value: 'ko', image: 'south-korea' },
  { name: 'Latvian (Lettish)', key: 'lv', value: 'lv', image: 'latvia' },
  { name: 'Lithuanian', key: 'lt', value: 'lt', image: 'lithuania' },
  {
    name: 'Macedonian',
    key: 'mk',
    value: 'mk',
    image: 'republic-of-macedonia'
  },
  { name: 'Malay', key: 'ms', value: 'ms', image: 'malaysia' },
  { name: 'Malayalam', key: 'ml', value: 'ml', image: 'india' },
  { name: 'Marathi', key: 'mr', value: 'mr', image: 'india' },
  { name: 'Mongolian', key: 'mn', value: 'mn', image: 'mongolia' },
  { name: 'Nepali', key: 'ne', value: 'ne', image: 'nepal' },
  { name: 'Norwegian', key: 'no', value: 'no', image: 'norway' },
  { name: 'Polish', key: 'pl', value: 'pl', image: 'republic-of-poland' },
  { name: 'Portuguese (Brazil)', key: 'ptb', value: 'pt', image: 'brazil' },
  { name: 'Portuguese (Portugal)', key: 'ptp', value: 'pt', image: 'portugal' },
  { name: 'Punjabi', key: 'pa', value: 'pa', image: 'india' },
  { name: 'Romanian', key: 'ro', value: 'ro', image: 'romania' },
  { name: 'Russian', key: 'ru', value: 'ru', image: 'russia' },
  { name: 'Serbian', key: 'sr', value: 'sr', image: 'serbia' },
  { name: 'Sindhi', key: 'sd', value: 'sd', image: 'india' },
  { name: 'Slovak', key: 'sk', value: 'sk', image: 'slovakia' },
  { name: 'Slovenian', key: 'sl', value: 'sl', image: 'slovenia' },
  { name: 'Spanish', key: 'es', value: 'es', image: 'spain' },
  {
    name: 'Swahili (Kiswahili)',
    key: 'sw',
    value: 'sw',
    image: 'south-africa'
  },
  { name: 'Swedish', key: 'sv', value: 'sv', image: 'sweden' },
  { name: 'Tamil', key: 'ta', value: 'ta', image: 'india' },
  { name: 'Telugu', key: 'te', value: 'te', image: 'india' },
  { name: 'Thai', key: 'th', value: 'th', image: 'thailand' },
  { name: 'Turkish', key: 'tr', value: 'tr', image: 'turkey' },
  { name: 'Ukrainian', key: 'uk', value: 'uk', image: 'ukraine' },
  { name: 'Urdu', key: 'ur', value: 'ur', image: 'pakistan' },
  { name: 'Uzbek', key: 'uz', value: 'uz', image: 'uzbekistn' },
  { name: 'Vietnamese', key: 'vi', value: 'vi', image: 'vietnam' }
]

const orderOptionsIntranet: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Document Title (A-Z)',
    key: 'titlea',
    value: 'Title:ascending',
    image: '',
    sort: 4
  },
  {
    name: 'Document Title (Z-A)',
    key: 'titled',
    value: 'Title:descending',
    image: '',
    sort: 5
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'Write:descending',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'Write:ascending',
    image: '',
    sort: 3
  },
  {
    name: 'Most Viewed',
    key: 'viewslifetime',
    value: 'ViewsLifeTime:descending',
    image: '',
    sort: 6
  }
]

/* OPTIONS O365 */
const documentOptionsOffice365: Filter[] = [
  {
    name: 'Powerpoint',
    key: 'PPT',
    value:
      '(filetype:odp OR filetype:ppt OR filetype:pptm OR filetype:pptx OR filetype:potm OR filetype:potx OR filetype:ppam OR filetype:ppsm OR filetype:ppsx OR filetype:ppa OR filetype:pps)',
    image: '',
    fileTypeIcon: 'pptx'
  },
  {
    name: 'Word',
    key: 'DOC',
    value:
      '(filetype:docx OR filetype:doc OR filetype:docm OR filetype:dot OR filetype:nws OR filetype:dotx)',
    image: '',
    fileTypeIcon: 'docx'
  },
  {
    name: 'Excel',
    key: 'XLS',
    value:
      '(filetype:odc OR filetype:ods OR filetype:xls OR filetype:xlsb OR filetype:xlsm OR filetype:xlsx OR filetype:xltm OR filetype:xltx OR filetype:xlam OR filetype:xlb OR filetype:xlc OR filetype:xlt)',
    image: '',
    fileTypeIcon: 'xlsx'
  },
  {
    name: 'OneNote',
    key: 'onenote',
    value: 'filetype:one',
    image: '',
    fileTypeIcon: 'one'
  },
  {
    name: 'Loop',
    key: 'loop',
    value: 'filetype:loop',
    image: '',
    fileTypeIcon: 'loop'
  },
  {
    name: 'PDF',
    key: 'PDF',
    value: 'filetype:pdf',
    image: '',
    fileTypeIcon: 'pdf'
  },
  {
    name: 'Photo',
    key: 'photo',
    value:
      '(filetype:bmp OR filetype:jpg OR filetype:jpeg OR filetype:png OR filetype:tiff OR filetype:gif OR FileType:rle OR FileType:wmf OR FileType:dib OR FileType:ico OR FileType:wpd OR FileType:odg)',
    image: '',
    fileTypeIcon: 'jpg'
  },
  {
    name: 'Video',
    key: 'video',
    value: "(filetype:mp4 OR ContentTypeId:'0x0120D520A8*')",
    image: '',
    fileTypeIcon: 'mp4'
  },
  {
    name: 'Page',
    key: 'page',
    value:
      '(filetype:html OR filetype:mhtml OR filetype:htm OR filetype:aspx OR filetype:asp)',
    image: '',
    fileTypeIcon: 'aspx'
  },
  {
    name: 'Email',
    key: 'Email',
    value: '(filetype:eml OR filetype:nws OR filetype:msg)',
    image: '',
    fileTypeIcon: 'eml'
  },
  {
    name: 'Microsoft Publisher',
    key: 'MicrosoftPublisher',
    value: 'filetype:pub',
    image: '',
    fileTypeIcon: 'pub'
  },
  {
    name: 'Microsoft XPS',
    key: 'MicrosoftXPS',
    value: 'filetype:xps',
    image: '',
    fileTypeIcon: 'xps'
  },
  {
    name: 'Open Document',
    key: 'OpenDocument',
    value: '(filetype:odc OR filetype:odp OR filetype:ods OR filetype:odt)',
    image: '',
    fileTypeIcon: 'odt'
  },
  {
    name: 'Rich Text',
    key: 'RichText',
    value: 'filetype:rtf',
    image: '',
    fileTypeIcon: 'rtf'
  },
  {
    name: 'Text',
    key: 'TextOnly',
    value: 'filetype:txt',
    image: '',
    fileTypeIcon: 'txt'
  },
  {
    name: 'Visio',
    key: 'Visio',
    value:
      '(filetype:vdw OR filetype:vdx OR filetype:vsd OR filetype:vsdm OR filetype:vsdx OR filetype:vss OR filetype:vssm OR filetype:vssx OR filetype:vst OR filetype:vstm OR filetype:vstx OR filetype:vsx OR filetype:vtx)',
    image: '',
    fileTypeIcon: 'vdw'
  },
  {
    name: 'XML',
    key: 'Xml',
    value: 'filetype:xml',
    image: '',
    fileTypeIcon: 'xml'
  },
  {
    name: 'Zip',
    key: 'Zip',
    value: 'filetype:zip',
    image: '',
    fileTypeIcon: 'zip'
  }
]

const dataSourceOptionsOffice365: Filter[] = [
  {
    name: 'All Data Sources',
    key: 'all',
    value: '',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'My Onedrive',
    key: 'onedrive',
    value: 'path:{oneDriveUrl}',
    image: ''
  },
  {
    name: 'Shared with me',
    key: 'sharedwMe',
    value: 'sharedwithusersowsuser:{upn}',
    image: ''
  }
]

const receivedAndSendOptionsOffice365: Filter[] = [
  {
    name: 'Received',
    key: 'all',
    value: 'recipients:{upn}',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'Send',
    key: 'send',
    value: "(from:{upn} OR from:'{displayname}')",
    image: ''
  },
  { name: 'Received and Send', key: 'allmails', value: '', image: '' }
]

const hasAttachmentOptionsOffice365: Filter[] = [
  {
    name: 'All Attachment Types',
    key: 'all',
    value: '',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'Has Attachments',
    key: 'hasAttach',
    value: 'hasattachment:true',
    image: ''
  },
  {
    name: 'Has No Attachments',
    key: 'noAttach',
    value: 'hasattachment:false',
    image: ''
  }
]

const orderOptionsOffice365: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Document Title (A-Z)',
    key: 'titlea',
    value: "[{'name':'name', 'isDescending':'false'}]",
    image: '',
    sort: 4
  },
  {
    name: 'Document Title (Z-A)',
    key: 'titled',
    value: "[{'name':'name', 'isDescending':'true'}]",
    image: '',
    sort: 5
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: "[{'name':'lastModifiedDateTime', 'isDescending':'true'}]",
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: "[{'name':'lastModifiedDateTime', 'isDescending':'false'}]",
    image: '',
    sort: 3
  }
]

const contentCategoryTypeOptionsEngagements: Filter[] = [
  {
    name: 'All Content Category Types',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  },
  // {
  //   name: 'Case Study',
  //   key: 'casestudy',
  //   value: '%22Engagement Experience:Case Study%22',
  //   image: ''
  // },
  // {
  //   name: 'Client Win',
  //   key: 'clientwin',
  //   value: '%22Engagement Experience:Client Win%22',
  //   image: ''
  // },
  {
    name: 'Engagement Credential',
    key: 'engagementcredential',
    value: '%22Engagement Experience:Engagement Credential%22',
    image: ''
  },
  // {
  //   name: 'Engagement Debrief',
  //   key: 'engagementdebrief',
  //   value: '%22Engagement Experience:Engagement Debrief%22',
  //   image: ''
  // },
  {
    name: 'Engagement Tombstone',
    key: 'engagementtombstone',
    value: '%22Engagement Experience:Engagement Tombstone%22',
    image: ''
  }
  // {
  //   name: 'Landmark Deal',
  //   key: 'landmarkdeal',
  //   value: '%22Engagement Experience:Landmark Deal%22',
  //   image: ''
  // },
  // {
  //   name: 'Qualifications Package',
  //   key: 'qualificationspackage',
  //   value: '%22Engagement Experience:Qualifications Package%22',
  //   image: ''
  // },
  // {
  //   name: 'Testimonial',
  //   key: 'testimonial',
  //   value: '%22Engagement Experience:Testimonial%22',
  //   image: ''
  // }
]

/* OPTIONS NEWS */
const languageOptionsNews: Filter[] = [
  {
    name: 'All Languages',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const publicationnameOptionsNews: Filter[] = [
  {
    name: 'All Publication Names',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const publicationtypeOptionsNews: Filter[] = [
  {
    name: 'All Publication Types',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const orderOptionsNews: Filter[] = [
  {
    name: 'Relevance',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  { name: 'Title (A-Z)', key: 'titlea', value: 'Title', image: '', sort: 4 },
  {
    name: 'Title (Z-A)',
    key: 'titled',
    value: 'Title desc',
    image: '',
    sort: 5
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'Date desc',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'Date',
    image: '',
    sort: 3
  }
]
/* const countryOptionsNews: Filter [] = [
  { name: 'All Geographies', key: 'all', value: '"all"', image: 'united-nations' },
  { name: 'Africa', key:'af', value: '"Africa"', image: 'south-africa' },
  { name: 'Australia & Fiji', key: 'auf', value: '"Australia%20%26%20Fiji"', image: 'australia'},
  { name: 'Canada', key: 'can', value: '"Canada"', image: 'canada' },
  { name: 'China (incl. Hong Kong & Macau)', key:'zn', value: '"China"', image: 'china' },
  { name: 'France', key: 'fr', value: '"France"', image: 'francy' },
  { name: 'Germany', key: 'de', value: '"Germany"', image: 'germany' },
  { name: 'India', key: 'in', value: '"India"', image: 'india' },
  { name: 'Italy (other portal)', key: 'it', value: '"Italy"', image: 'italy' },
  { name: 'Japan (other portal)', key: 'jp', value: '"Japan"', image: 'japan' },
  { name: 'KPMG Global', key: 'global', value: '"Global"', image: 'united-nations' },
  { name: 'Luxembourg', key: 'lx', value: '"Luxembourg"', image: 'luxembourg' },
  { name: 'Middle East & South Asia', key: 'mesa', value: '"Middle%20East%20%26%20South%20Asia"', image: 'united-nations' },
  { name: 'Netherlands', key: 'nt', value: '"Netherlands"', image: 'netherlands' },
  { name: 'Russia & CIS (other portal)', key: 'ru', value: '"Commonwealth%20of%20Independent%20States"', image: 'russia' },
  { name: 'Singapore', key: 'sp', value: '"Singapore"' , image: 'singapore'},
  { name: 'Spain', key: 'es', value: '"Spain"', image: 'spain'},
  { name: 'Sweden', key: 'sw', value: '"Sweden"', image: 'sweden' },
  { name: 'Switzerland', key: 'ch', value: '"Switzerland"', image: 'switzerland'},
  { name: 'United Kingdom', key: 'gb', value: '"United%20Kingdom"', image: 'united-kingdom' },
  { name: 'United States (other portal)', key: 'us', value: '"United%20States"', image: 'united-states-of-america'  }
]
const publicationlocationOptionsNews: Filter [] = [
  { name: 'All publication locations', key: 'all', value: 'all', image: '' }
] */

/*
const functionOptions: Filter [] = [
  { name: 'All Services', key: 'all', value: 'all', image: '' },
  { name: 'Audit & Assurance', key: 'aa', value: '("Audit%20%2c%20Assurance")', image: '' },
  { name: 'Tax', key: 'tax', value: '("Tax")', image: '' },
  { name: 'Deal Advisory', key: 'da', value: '("Deal%20Advisory")', image: '' },
  { name: 'Management Consulting', key: 'mc', value: '("Management%20Consulting")', image: '' },
  { name: 'Risk Consulting', key: 'rc', value: '("Risk%20Consulting")', image: '' },
  { name: 'Alliances', key: 'alli', value: '("Alliances")', image: '' },
  { name: 'KPMG Shared Services', key: 'ss', value: '("KPMG$20Shared%20Services")', image: '' },
  { name: 'Markets', key: 'mkt', value: '("Markets")', image: '' }
]
const segmentOptions: Filter [] = [
  { name: 'All Segments', key: 'all', value: 'all', image: '' },
  { name: 'Consumer ＆ Retail', key: 'cr', value: '("Consumer%20%26%20Retail")', image: '' },
  { name: 'Energy and Natural Resources', key: 'enr', value: '("Energy%20and%20Natural%20Resources")', image: '' },
  { name: 'Financial Services', key: 'fs', value: '("Financial%20Services")', image: '' },
  { name: 'Industrial Markets', key: 'im', value: '("Industrial%20Markets")', image: '' },
  { name: 'Infrastructure, Government and Healthcare', key: 'igh', value: '("Infrastructure%2C%20Government%20and%20Healthcare")',  image: ''   },
  { name: 'Life Sciences', key: 'ls', value: '("Life%20Sciences")', image: '' },
  { name: 'Private Equity', key: 'pe', value: '("Private%20Equity")', image: '' },
  { name: 'Technology, Media ＆ Telecommunications', key: 'tmt', value: '("Technology%2C%20Media%20%26%20Telecommunications")', image: '' }
]*/

/* OPTIONS STATISTICS */
const orderOptionsStatistics: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: '1',
    image: '',
    sort: 2
  },
  { name: 'Popularity', key: 'popd', value: '2', image: '', sort: 3 }
]

/* OPTIONS Videos */
const orderOptionsVideos: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'Write:descending',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'Write:ascending',
    image: '',
    sort: 3
  },
  {
    name: 'Most Viewed',
    key: 'viewslifetime',
    value: 'ViewsLifeTime:descending',
    image: '',
    sort: 4
  }
]

/* OPTIONS KLARDENKER */
// const orderOptionsKlardenker: Filter [] = [
//   { name: 'Relevance', key: '', value: '', image: '' },
//   { name: 'Date (newest-oldest)', key: 'dated', value: 'date', image: '' },
// ]

/* OPTIONS KPMG WEBSITES / AEM */
const localeOptionsKPMG: Filter[] = [
  {
    name: 'Global (xx/en)',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true
  },
  { name: 'India (in/en)', key: 'in', value: 'in/en', image: '' },
  {
    name: 'Czech Republic (cz/en)',
    key: 'cz',
    value: 'cz/en',
    image: ''
  }
]
const typeOptionsKPMG: Filter[] = [
  { name: 'All', key: '', value: '', image: '', isDefaultFilter: true },
  { name: 'Article', key: 'ar', value: 'Article', image: '' },
  { name: 'Press Releases', key: 'pr', value: 'Press%20Releases', image: '' },
  { name: 'Contact', key: 'co', value: 'Contact', image: '' },
  { name: 'Location', key: 'lo', value: 'Location', image: '' }
]
const orderOptionsKPMG: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: 'Relevance',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Sort by date (latest first)',
    key: 'dated',
    value: 'Date',
    image: '',
    sort: 2
  } /*,
  { name: 'Most Viewed', key: 'mv', value: 'Most%20Viewed', image: '' }*/
]
// const spaceOptionsSource: Filter[] = [
//   { name: 'All', key: '', value: 'all', image: '', isDefaultFilter: true },
//   {
//     name: 'Favorite spaces',
//     key: 'favoritespaces',
//     value: 'space.type+%3D+"favourite"',
//     image: ''
//   },
//   {
//     name: 'Site spaces',
//     key: 'sitespaces',
//     value: 'space.type+%3D+"global"',
//     image: ''
//   },
//   {
//     name: 'Personal spaces',
//     key: 'personalspaces',
//     value: 'space.type+%3D+"personal"',
//     image: ''
//   }
// ]
const typeOptionsSource: Filter[] = [
  {
    name: 'Page',
    key: 'page',
    value: 'page',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'Attachment',
    key: 'attachment',
    value: 'attachment',
    image: '',
    isDefaultFilter: true
  },
  { name: 'Space', key: 'space', value: 'space', image: '' },
  { name: 'Blog', key: 'blogpost', value: 'blogpost', image: '' }
]
const orderOptionsSource: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Document Title (A-Z)',
    key: 'titlea',
    value: 'order+by+title+asc',
    image: '',
    sort: 4
  },
  {
    name: 'Document Title (Z-A)',
    key: 'titled',
    value: 'order+by+title+desc',
    image: '',
    sort: 5
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'order+by+lastmodified+desc',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'order+by+lastmodified+asc',
    image: '',
    sort: 3
  }
]
const timeRangeFilter: Filter[] = [
  {
    name: 'All',
    key: 'all',
    value: '',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'Last 24 Hours',
    key: 'lastday',
    value: 'lastday',
    image: ''
  },
  {
    name: 'Last Week',
    key: 'lastweek',
    value: 'lastweek',
    image: ''
  },
  {
    name: 'Last Month',
    key: 'lastmonth',
    value: 'lastmonth',
    image: ''
  },
  {
    name: 'Last 3 Months',
    key: 'last3month',
    value: 'last3month',
    image: ''
  },
  {
    name: 'Last Year',
    key: 'lastyear',
    value: 'lastyear',
    image: ''
  },
  {
    name: 'Older than a Year',
    key: 'olderyear',
    value: 'olderyear',
    image: ''
  },
  {
    name: 'Date Time Select',
    key: 'customRange',
    value: 'customRange',
    image: ''
  }
]

/* OPTIONS Market Research */
const countryOptionsMarketResearch: Filter[] = [
  {
    name: 'All Countries',
    key: '',
    value: '',
    image: 'united-nations',
    isDefaultFilter: true
  },
  {
    name: 'Andorra',
    key: 'AND',
    value: 'AND',
    image: ''
  },
  {
    name: 'Argentina',
    key: 'ARG',
    value: 'ARG',
    image: ''
  },
  /*{
    name: 'Aurum Mining Funds',
    key: 'VUAURT',
    value: 'AUR',
    image: ''
  },*/
  {
    name: 'Australia',
    key: 'AUS',
    value: 'AUS',
    image: ''
  },
  {
    name: 'Austria',
    key: 'AUT',
    value: 'AUT',
    image: ''
  },
  {
    name: 'Bahamas',
    key: 'BHS',
    value: 'BHS',
    image: ''
  },
  {
    name: 'Bahrain',
    key: 'BHR',
    value: 'BHR',
    image: ''
  },
  /*{
    name: 'Barbados',
    key: 'BRB',
    value: 'BRB',
    image: ''
  },*/
  {
    name: 'Belgium',
    key: 'BEL',
    value: 'BEL',
    image: ''
  },
  {
    name: 'Bermuda',
    key: 'BMU',
    value: 'BMU',
    image: ''
  },
  {
    name: 'Botswana',
    key: 'BWA',
    value: 'BWA',
    image: ''
  },
  /*{
    name: 'Brazil',
    key: 'BRA',
    value: 'BRA',
    image: ''
  },
  {
    name: 'Bulgaria',
    key: 'BGR',
    value: 'BGR',
    image: ''
  },*/
  {
    name: 'Canada',
    key: 'CAN',
    value: 'CAN',
    image: ''
  },
  /*{
    name: 'Canadian Dollars',
    key: 'CAD',
    value: 'CAD',
    image: ''
  },*/
  {
    name: 'Cayman Islands',
    key: 'CYM',
    value: 'CYM',
    image: ''
  },
  {
    name: 'Chile',
    key: 'CHL',
    value: 'CHL',
    image: ''
  },
  {
    name: 'China',
    key: 'CHN',
    value: 'CHN',
    image: ''
  },
  /*{
    name: 'Cook Islands',
    key: 'COK',
    value: 'COK',
    image: ''
  },*/
  {
    name: 'Cypress',
    key: 'CYP',
    value: 'CYP',
    image: ''
  },
  {
    name: 'Czech Republic',
    key: 'CZE',
    value: 'CZE',
    image: ''
  },
  {
    name: 'Denmark',
    key: 'DNK',
    value: 'DNK',
    image: ''
  },
  /*{
    name: 'Dublin',
    key: 'DUB',
    value: 'DUB',
    image: ''
  },*/
  {
    name: 'Egypt',
    key: 'EGY',
    value: 'EGY',
    image: ''
  },
  {
    name: 'Finland',
    key: 'FIN',
    value: 'FIN',
    image: ''
  },
  {
    name: 'France',
    key: 'FRA',
    value: 'FRA',
    image: ''
  },
  {
    name: 'Germany',
    key: 'DEU',
    value: 'DEU',
    image: ''
  },
  /*{
    name: 'Gibraltar',
    key: 'GIB',
    value: 'GIB',
    image: ''
  },*/
  {
    name: 'Greece',
    key: 'GRC',
    value: 'GRC',
    image: ''
  },
  /*{
    name: 'Guernsey',
    key: 'GGY',
    value: 'GGY',
    image: ''
  },
  {
    name: 'Hedge Fund',
    key: 'HFF',
    value: 'HFF',
    image: ''
  },*/
  {
    name: 'Hong Kong',
    key: 'HKG',
    value: 'HKG',
    image: ''
  },
  {
    name: 'Hungary',
    key: 'HUN',
    value: 'HUN',
    image: ''
  },
  {
    name: 'Iceland',
    key: 'ISL',
    value: 'ISL',
    image: ''
  },
  {
    name: 'India',
    key: 'IND',
    value: 'IND',
    image: ''
  },
  {
    name: 'Indonesia',
    key: 'IDN',
    value: 'IDN',
    image: ''
  },
  /*{
    name: 'International',
    key: 'INTL',
    value: 'INTL',
    image: ''
  },*/
  {
    name: 'Ireland',
    key: 'IRL',
    value: 'IRL',
    image: ''
  },
  /*{
    name: 'Isle of Man',
    key: 'IMN',
    value: 'IMN',
    image: ''
  },*/
  {
    name: 'Israel',
    key: 'ISR',
    value: 'ISR',
    image: ''
  },
  {
    name: 'Italy',
    key: 'ITA',
    value: 'ITA',
    image: ''
  },
  {
    name: 'Japan',
    key: 'JPN',
    value: 'JPN',
    image: ''
  },
  /*{
    name: 'Jersey',
    key: 'JEY',
    value: 'JEY',
    image: ''
  },*/
  {
    name: 'Jordan',
    key: 'JOR',
    value: 'JOR',
    image: ''
  },
  {
    name: 'Korea, Republic of',
    key: 'KOR',
    value: 'KOR',
    image: ''
  },
  {
    name: 'Kuwait',
    key: 'KWT',
    value: 'KWT',
    image: ''
  },
  /*{
    name: 'Latvia',
    key: 'LVA',
    value: 'LVA',
    image: ''
  },*/
  {
    name: 'Lebanon',
    key: 'LBN',
    value: 'LBN',
    image: ''
  },
  {
    name: 'Liechtenstein',
    key: 'LIE',
    value: 'LIE',
    image: ''
  },
  /*{
    name: 'Lithuania',
    key: 'LTU',
    value: 'LTU',
    image: ''
  },*/
  {
    name: 'Luxembourg',
    key: 'LUX',
    value: 'LUX',
    image: ''
  },
  /*{
    name: 'Macau',
    key: 'MAC',
    value: 'MAC',
    image: ''
  },*/
  {
    name: 'Malaysia',
    key: 'MYS',
    value: 'MYS',
    image: ''
  },
  {
    name: 'Malta',
    key: 'MLT',
    value: 'MLT',
    image: ''
  },
  {
    name: 'Mauritius',
    key: 'MUS',
    value: 'MUS',
    image: ''
  },
  {
    name: 'Mexico',
    key: 'MEX',
    value: 'MEX',
    image: ''
  },
  /*{
    name: 'Monaco',
    key: 'MCO',
    value: 'MCO',
    image: ''
  },*/
  {
    name: 'Morocco',
    key: 'MAR',
    value: 'MAR',
    image: ''
  },
  {
    name: 'Netherlands',
    key: 'NLD',
    value: 'NLD',
    image: ''
  },
  {
    name: 'Netherlands Antilles',
    key: 'ANT',
    value: 'ANT',
    image: ''
  },
  {
    name: 'New Zealand',
    key: 'NZL',
    value: 'NZL',
    image: ''
  },
  /*{
    name: 'New Zealand Dollars',
    key: 'NZD',
    value: 'NZD',
    image: ''
  },*/
  {
    name: 'North Korea',
    key: 'PRK',
    value: 'PRK',
    image: ''
  },
  {
    name: 'Norway',
    key: 'NOR',
    value: 'NOR',
    image: ''
  },
  /*{
    name: 'Offshore Fund',
    key: 'OFFSHORE',
    value: 'OFFSHORE',
    image: ''
  },
  {
    name: 'Oman',
    key: 'OMN',
    value: 'OMN',
    image: ''
  },*/
  {
    name: 'Pakistan',
    key: 'PAK',
    value: 'PAK',
    image: ''
  },
  {
    name: 'Panama',
    key: 'PAN',
    value: 'PAN',
    image: ''
  },
  {
    name: 'Peru',
    key: 'PER',
    value: 'PER',
    image: ''
  },
  {
    name: 'Phillippines',
    key: 'PHL',
    value: 'PHL',
    image: ''
  },
  {
    name: 'Poland',
    key: 'POL',
    value: 'POL',
    image: ''
  },
  {
    name: 'Portugal',
    key: 'PRT',
    value: 'PRT',
    image: ''
  },
  {
    name: 'Puerto Rico',
    key: 'PRI',
    value: 'PRI',
    image: ''
  },
  {
    name: 'Qatar',
    key: 'QAT',
    value: 'QAT',
    image: ''
  },
  {
    name: 'Russian Federation',
    key: 'RUS',
    value: 'RUS',
    image: ''
  },
  {
    name: 'Saudi Arabia',
    key: 'SAU',
    value: 'SAU',
    image: ''
  },
  {
    name: 'Singapore',
    key: 'SGP',
    value: 'SGP',
    image: ''
  },
  {
    name: 'Slovakia (Slovak Republic)',
    key: 'SVK',
    value: 'SVK',
    image: ''
  },
  /*{
    name: 'Slovenia',
    key: 'SVN',
    value: 'SVN',
    image: ''
  },*/
  {
    name: 'Spain',
    key: 'ESP',
    value: 'ESP',
    image: ''
  },
  /*{
    name: 'Swaziland',
    key: 'SWZ',
    value: 'SWZ',
    image: ''
  },*/
  {
    name: 'Sweden',
    key: 'SWE',
    value: 'SWE',
    image: ''
  },
  {
    name: 'Switzerland',
    key: 'CHE',
    value: 'CHE',
    image: ''
  },
  {
    name: 'Taiwan',
    key: 'TWN',
    value: 'TWN',
    image: ''
  },
  /*{
    name: 'Taiwan De-authorised',
    key: 'EXTAIWAN',
    value: 'EXTAIWAN',
    image: ''
  },*/
  {
    name: 'Thailand',
    key: 'THA',
    value: 'THA',
    image: ''
  },
  {
    name: 'The British Virgin Islands',
    key: 'VGB',
    value: 'VGB',
    image: ''
  },
  {
    name: 'Trinidad and Tobago',
    key: 'TTO',
    value: 'TTO',
    image: ''
  },
  {
    name: 'Tunisia',
    key: 'TUN',
    value: 'TUN',
    image: ''
  },
  {
    name: 'Turkey',
    key: 'TUR',
    value: 'TUR',
    image: ''
  },
  /*{
    name: 'United Arab Emirates',
    key: 'ARE',
    value: 'ARE',
    image: ''
  },*/
  {
    name: 'United Kingdom',
    key: 'GBR',
    value: 'GBR',
    image: ''
  },
  {
    name: 'United States',
    key: 'USA',
    value: 'USA',
    image: ''
  },
  /*{
    name: 'USA Equity',
    key: 'USAEQ',
    value: 'USAEQ',
    image: ''
  },
  {
    name: 'USA Fixed Income',
    key: 'USAFI',
    value: 'USAFI',
    image: ''
  },*/
  {
    name: 'Vanuatu',
    key: 'VUT',
    value: 'VUT',
    image: ''
  },
  {
    name: 'Vietnam',
    key: 'VNM',
    value: 'VNM',
    image: ''
  },
  {
    name: 'ZAF (South Africa)',
    key: 'ZAF',
    value: 'ZAF',
    image: ''
  }
]

const languageOptionsMarketResearch: Filter[] = [
  {
    name: 'All Languages',
    key: 'all',
    value: 'all',
    image: 'united-nations',
    isDefaultFilter: true
  },
  { name: 'Abkhaz', key: 'ab', value: 'ab', image: 'abkhazia' },
  { name: 'Afrikaans', key: 'af', value: 'af', image: 'south-africa' },
  { name: 'Arabic', key: 'ar', value: 'ar', image: 'united-arab-emirates' },
  { name: 'Bosnian', key: 'bs', value: 'bs', image: 'bosnia-and-herzegovina' },
  { name: 'Bulgarian', key: 'bg', value: 'bg', image: 'bulgaria' },
  { name: 'Burmese', key: 'my', value: 'my', image: 'myanmar' },
  { name: 'Chinese', key: 'zhs', value: 'zhs', image: 'china' },
  { name: 'Croatian', key: 'hr', value: 'hr', image: 'croatia' },
  { name: 'Czech', key: 'cs', value: 'cs', image: 'czech-republic' },
  { name: 'Danish', key: 'da', value: 'da', image: 'denmark' },
  { name: 'Dutch', key: 'nl', value: 'nl', image: 'netherlands' },
  { name: 'English', key: 'en', value: 'en', image: 'united-kingdom' },
  { name: 'Estonian', key: 'et', value: 'et', image: 'estonia' },
  { name: 'Finnish', key: 'fi', value: 'fi', image: 'finland' },
  { name: 'French', key: 'fr', value: 'fr', image: 'france' },
  { name: 'German', key: 'de', value: 'de', image: 'germany' },
  { name: 'Greek', key: 'el', value: 'el', image: 'greece' },
  { name: 'Hebrew', key: 'he', value: 'he', image: 'israel' },
  { name: 'Hungarian', key: 'hu', value: 'hu', image: 'hungary' },
  { name: 'Icelandic', key: 'is', value: 'is', image: 'iceland' },
  { name: 'Indonesian', key: 'id', value: 'id', image: 'indonesia' },
  { name: 'Italian', key: 'it', value: 'it', image: 'italy' },
  { name: 'Japanese', key: 'ja', value: 'ja', image: 'japan' },
  { name: 'Kazakh', key: 'kk', value: 'kk', image: 'kazakhstan' },
  { name: 'Korean', key: 'ko', value: 'ko', image: 'south-korea' },
  { name: 'Latvian (Lettish)', key: 'lv', value: 'lv', image: 'latvia' },
  { name: 'Lithuanian', key: 'lt', value: 'lt', image: 'lithuania' },
  {
    name: 'Macedonian',
    key: 'mk',
    value: 'mk',
    image: 'republic-of-macedonia'
  },
  { name: 'Malay', key: 'ms', value: 'ms', image: 'malaysia' },
  { name: 'Mongolian', key: 'mn', value: 'mn', image: 'mongolia' },
  { name: 'Norwegian', key: 'no', value: 'no', image: 'norway' },
  { name: 'Polish', key: 'pl', value: 'pl', image: 'republic-of-poland' },
  { name: 'Portuguese', key: 'ptp', value: 'ptp', image: 'brazil' },
  { name: 'Romanian', key: 'ro', value: 'ro', image: 'romania' },
  { name: 'Russian', key: 'ru', value: 'ru', image: 'russia' },
  { name: 'Sardinian', key: 'sc', value: 'sc', image: 'sardinia' },
  { name: 'Serbian', key: 'sr', value: 'sr', image: 'serbia' },
  { name: 'Slovak', key: 'sk', value: 'sk', image: 'slovakia' },
  { name: 'Slovenian', key: 'sl', value: 'sl', image: 'slovenia' },
  { name: 'Spanish', key: 'es', value: 'es', image: 'spain' },
  { name: 'Swedish', key: 'sv', value: 'sv', image: 'sweden' },
  { name: 'Persian (Farsi)', key: 'fa', value: 'fa', image: 'iran' },
  { name: 'Thai', key: 'th', value: 'th', image: 'thailand' },
  { name: 'Turkish', key: 'tr', value: 'tr', image: 'turkey' },
  { name: 'Ukrainian', key: 'uk', value: 'uk', image: 'ukraine' },
  { name: 'Vietnamese', key: 'vi', value: 'vi', image: 'vietnam' }
]
const orderOptionsMarketResearch: Filter[] = [
  {
    name: 'Date (Newest-Oldest)',
    key: 'DateDesc',
    value: 'DateDesc',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'DateAsc',
    value: 'DateAsc',
    image: '',
    sort: 2
  },
  {
    name: 'Primary Ticker (A-Z)',
    key: 'PrimaryTickerAsc',
    value: 'PrimaryTickerAsc',
    image: '',
    sort: 5
  },
  {
    name: 'Primary Ticker (Z-A)',
    key: 'PrimaryTickerDesc',
    value: 'PrimaryTickerDesc',
    image: '',
    sort: 6
  },
  {
    name: 'Pages Descending',
    key: 'PagesDesc',
    value: 'PagesDesc',
    image: '',
    sort: 4
  },
  {
    name: 'Pages Ascending',
    key: 'PagesAsc',
    value: 'PagesAsc',
    image: '',
    sort: 3
  }
]

/* OPTIONS MPP */
const manualOptionsMpp: Filter[] = [
  {
    name: 'All Manuals',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const countryOptionsMpp: Filter[] = [
  {
    name: 'All Countries',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const documentOptionsMpp: Filter[] = [
  {
    name: 'All Document Types',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]
const servicelineOptionsMpp: Filter[] = []

/* OPTIONS ALEX */
const orderOptionsAlex: Filter[] = [
  {
    name: 'Relevance',
    key: 'relevance',
    value: 'Relevance',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'Date',
    image: ''
  },
  {
    name: 'Title (A-Z)',
    key: 'titlea',
    value: 'Title',
    image: ''
  }
]

/* FILTER CATEGORIES */
export const intranet: FilterCategory[] = [
  {
    name: 'All Portals',
    key: 'country',
    value: 'DepartmentId',
    options: portalOptionsIntranet
  },
  {
    name: 'All Document Types',
    key: 'file',
    value: 'fileextension',
    options: documentOptionsIntranet,
    noSorting: true,
    multiselect: true
  },
  {
    name: 'All Languages',
    key: 'language',
    value: 'DetectedLanguage',
    options: languageOptionsIntranet
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsIntranet
  }
]
export const engagements: FilterCategory[] = [
  {
    name: 'All Content Category Types',
    key: 'cattype',
    value: 'kpmgdatacontentcategorytype',
    options: contentCategoryTypeOptionsEngagements
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  }
]
export const news: FilterCategory[] = [
  {
    name: 'All Languages',
    key: 'language',
    value: 'language',
    options: languageOptionsNews,
    optionsKey: 'language'
  },
  {
    name: 'All Publication Names',
    key: 'publisher',
    value: 'publisher',
    options: publicationnameOptionsNews,
    optionsKey: 'publisher'
  },
  {
    name: 'All Publication Types',
    key: 'publicationtype',
    value: 'publicationtype',
    options: publicationtypeOptionsNews,
    optionsKey: 'publicationtype'
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsNews
  }
]
export const people: FilterCategory[] = [
  {
    name: 'All Countries',
    key: 'countriesFilter',
    value: 'RefinableString59',
    options: [],
    optionsKey: 'countries',
    multiselect: true,
    mulitSelectRefinerRule: buildCountryRefinerForPeople,
    noSorting: true
  },
  {
    name: 'All Cities',
    key: 'cities',
    value: 'RefinableString60',
    options: [],
    optionsKey: 'cities',
    multiselect: true,
    mulitSelectRefinerRule: buildCityRefinerForPeople,
    noSorting: true
  },
  {
    name: 'All Departments',
    key: 'departments',
    value: 'Department',
    options: [],
    optionsKey: 'departments',
    multiselect: true,
    mulitSelectRefinerRule: buildDepartmentRefinerForPeople,
    noSorting: true
  },
  {
    name: 'All JobTitles',
    key: 'jobtitles',
    value: 'JobTitle',
    options: [],
    optionsKey: 'jobtitles',
    multiselect: true,
    mulitSelectRefinerRule: buildJobTitleRefinerForPeople,
    noSorting: true
  }
]
export const statistics: FilterCategory[] = [
  {
    name: 'Premium Content',
    key: 'premium',
    value: 'premium',
    options: []
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsStatistics
  }
]
export const office365files: FilterCategory[] = [
  {
    name: 'All Document Types',
    key: 'file',
    value: 'fileextension',
    options: documentOptionsOffice365,
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildFilesRefinerForOffice365
  },
  {
    name: 'All Data Sources',
    key: 'o365ds',
    value: 'o365ds',
    options: dataSourceOptionsOffice365,
    noSorting: true
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsOffice365
  }
]
export const office365mails: FilterCategory[] = [
  {
    name: 'Received',
    key: 'mtype',
    value: 'receivedOrSend',
    options: receivedAndSendOptionsOffice365,
    noSorting: true
  },
  {
    name: 'All Attachment Types',
    key: 'atype',
    value: 'attachmentType',
    options: hasAttachmentOptionsOffice365,
    noSorting: true
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  }
]
export const office365calendar: FilterCategory[] = []

export const office365sites: FilterCategory[] = [
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsOffice365
  }
]

export const office365teams: FilterCategory[] = [
  {
    name: 'From',
    key: 'cfrom',
    value: 'cfrom',
    options: [
      {
        name: 'All',
        key: 'all',
        value: '',
        image: '',
        isDefaultFilter: true
      },
      {
        name: 'Me',
        key: 'me',
        value: "(from:{upn} OR from:'{displayname}')",
        image: ''
      }
    ],
    noSorting: true
  },
  {
    name: 'All Attachment Types',
    key: 'atype',
    value: 'attachmentType',
    options: hasAttachmentOptionsOffice365,
    noSorting: true
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: '@mentions me',
    key: 'mentionsme',
    value: 'mentionsme',
    options: [
      {
        name: 'all',
        key: 'all',
        value: '',
        image: '',
        isDefaultFilter: true
      },
      {
        name: '@mentions me',
        key: '1',
        value: 'isMentioned:true',
        image: ''
      }
    ],
    noSorting: true,
    isToggleFilter: true
  }
]

export const videos: FilterCategory[] = [
  {
    name: 'All Portals',
    key: 'country',
    value: 'DepartmentId',
    options: portalOptionsIntranet
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsVideos
  }
]
export const klardenker: FilterCategory[] = [
  // {
  //   name: 'Relevance',
  //   key: 'orderBy',
  //   value: 'orderBy',
  //   options: orderOptionsKlardenker
  // }
]
export const kpmgwebsites: FilterCategory[] = [
  {
    name: 'Locale',
    key: 'locale',
    value: 'locale',
    options: localeOptionsKPMG
  },
  {
    name: 'Type',
    key: 'type',
    value: 'type',
    options: typeOptionsKPMG
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsKPMG
  }
]
export const contentfinder: FilterCategory[] = [
  // {
  //   name: 'Relevance',
  //   key: 'orderBy',
  //   value: 'orderBy',
  //   options: orderOptionsKlardenker
  // }
]
export const alex: FilterCategory[] = [
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsAlex
  }
]
export const source: FilterCategory[] = [
  // {
  //   name: 'Space',
  //   key: 'space',
  //   value: 'space',
  //   options: spaceOptionsSource
  // },
  {
    name: 'Type',
    key: 'type',
    value: 'type',
    multiselect: true,
    mulitSelectRefinerRule: buildTypeRefinerForSource,
    options: typeOptionsSource
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsSource
  }
]
export const kpmg_mpp: FilterCategory[] = [
  {
    name: 'All Countries',
    key: 'country',
    value: 'country',
    options: countryOptionsMpp,
    optionsKey: 'country'
  },
  {
    name: 'All Manuals',
    key: 'manual',
    value: 'manual',
    options: manualOptionsMpp,
    optionsKey: 'manual'
  },
  {
    name: 'All Document Types',
    key: 'filetype',
    value: 'filetype',
    options: documentOptionsMpp,
    optionsKey: 'filetype'
  },
  {
    name: 'Service Line',
    key: 'serviceline',
    value: 'serviceline',
    options: servicelineOptionsMpp,
    optionsKey: 'serviceline'
  }
]

export const knowledgeexchange: FilterCategory[] = [
  {
    name: 'All Document Types',
    key: 'filetype',
    value: 'FileType',
    options: [
      {
        name: 'All Document Types',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'filetype'
  },
  {
    name: 'All Sectors',
    key: 'sector',
    value: 'Sector',
    options: [
      {
        name: 'All Sectors',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'sector'
  },
  {
    name: 'All ContentTypes',
    key: 'contenttype',
    value: 'ContentType',
    options: [
      {
        name: 'All ContentTypes',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'contenttype'
  },
  {
    name: 'All Corridors',
    key: 'corridor',
    value: 'Corridor',
    options: [
      {
        name: 'All Corridors',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'corridor'
  },
  {
    name: 'All Countries',
    key: 'kxcountry',
    value: 'kxcountry',
    options: [
      {
        name: 'All Countries',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'kxcountry'
  },
  {
    name: 'All Functions',
    key: 'function',
    value: 'Function',
    options: [
      {
        name: 'All Functions',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'function'
  },
  {
    name: 'All ServiceLines',
    key: 'serviceline',
    value: 'ServiceLine',
    options: [
      {
        name: 'All ServiceLines',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'serviceline'
  },
  {
    name: 'All SubServiceLines',
    key: 'subserviceline',
    value: 'SubServiceLine',
    options: [
      {
        name: 'All SubServiceLines',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'subserviceline'
  },
  {
    name: 'All Solutions',
    key: 'solution',
    value: 'Solution',
    options: [
      {
        name: 'All Solutions',
        key: 'all',
        value: 'all',
        image: '',
        isDefaultFilter: true
      }
    ],
    noSorting: true,
    multiselect: true,
    mulitSelectRefinerRule: buildRefinerForKnowledgeExchange,
    optionsKey: 'solution'
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  }
]

export const knowledgeexchangeCatalog: FilterCategory[] = [
  ...knowledgeexchange
    .filter((item) => item.key !== 'filetype')
    .map((item) => Object.assign({}, item))
]

export const marketresearch: FilterCategory[] = [
  {
    name: 'All Countries',
    key: 'countrymr',
    value: 'countrymr',
    options: countryOptionsMarketResearch
  },
  {
    name: 'All Languages',
    key: 'language',
    value: 'DetectedLanguage',
    options: languageOptionsMarketResearch
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsMarketResearch
  }
]

const orderOptionsIbfd: Filter[] = [
  {
    name: 'Relevance',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },
  {
    name: 'Relevance (Ascending)',
    key: 'relevance_asc',
    value: 'relevance_asc',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'Date desc',
    image: '',
    sort: 3
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'Date',
    image: '',
    sort: 4
  }
]

const countryOptionsIbfd: Filter[] = [
  {
    name: 'All Countries',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]

const regionOptionsIbfd: Filter[] = [
  {
    name: 'All Regions',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]

const organisationOptionsIbfd: Filter[] = [
  {
    name: 'All Organisations',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]

const topicOptionsIbfd: Filter[] = [
  {
    name: 'All Topics',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]

export const ibfd: FilterCategory[] = [
  {
    name: 'All Regions',
    key: 'regionFilter',
    value: 'regionFilter',
    multiselect: true,
    mulitSelectRefinerRule: (value: string[]) => value,
    options: regionOptionsIbfd,
    optionsKey: 'regionFilter'
  },
  {
    name: 'All Countries',
    key: 'countriesFilter',
    value: 'countriesFilter',
    multiselect: true,
    mulitSelectRefinerRule: (value: string[]) => value,
    options: countryOptionsIbfd,
    optionsKey: 'countriesFilter'
  },
  {
    name: 'All Organisations',
    key: 'orgFilter',
    value: 'orgFilter',
    multiselect: true,
    mulitSelectRefinerRule: (value: string[]) => value,
    options: organisationOptionsIbfd,
    optionsKey: 'orgFilter'
  },
  {
    name: 'All Topics',
    key: 'topicFilter',
    value: 'topicFilter',
    multiselect: true,
    mulitSelectRefinerRule: (value: string[]) => value,
    options: topicOptionsIbfd,
    optionsKey: 'topicFilter'
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsIbfd
  }
]

export const ImageTypeOptions: Filter[] = [
  {
    name: 'All Image Types',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  },
  {
    name: 'BMP',
    key: 'bmp',
    value: 'bmp',
    image: '',
    fileTypeIcon: 'bmp'
  },
  {
    name: 'DIB',
    key: 'dib',
    value: 'dib',
    image: '',
    fileTypeIcon: 'dib'
  },
  {
    name: 'GIF',
    key: 'gif',
    value: 'gif',
    image: '',
    fileTypeIcon: 'gif'
  },
  {
    name: 'ICO',
    key: 'ico',
    value: 'ico',
    image: '',
    fileTypeIcon: 'ico'
  },
  {
    name: 'JPG',
    key: 'jpg',
    value: 'jpg',
    image: '',
    fileTypeIcon: 'jpg'
  },
  {
    name: 'ODG',
    key: 'odg',
    value: 'odg',
    image: '',
    fileTypeIcon: 'odg'
  },
  {
    name: 'PNG',
    key: 'png',
    value: 'png',
    image: '',
    fileTypeIcon: 'png'
  },
  {
    name: 'RLE',
    key: 'rle',
    value: 'rle',
    image: '',
    fileTypeIcon: 'rle'
  },
  {
    name: 'TIFF',
    key: 'tiff',
    value: 'tiff',
    image: '',
    fileTypeIcon: 'tiff'
  },
  {
    name: 'WMF',
    key: 'wmf',
    value: 'wmf',
    image: '',
    fileTypeIcon: 'wmf'
  },
  {
    name: 'WPD',
    key: 'wpd',
    value: 'wpd',
    image: '',
    fileTypeIcon: 'wpd'
  }
]

export const images: FilterCategory[] = [
  {
    name: 'Image Types',
    key: 'imageType',
    value: 'imageType',
    options: ImageTypeOptions
  },
  {
    name: 'Show all images',
    key: 'size',
    value: 'size',
    isToggleFilter: true,
    options: [
      {
        name: 'Show all images',
        key: 'all',
        value: 'range(30000, max)',
        isDefaultFilter: true
      },
      {
        name: 'Show all images',
        key: '1',
        value: ''
      }
    ]
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  }
]

const orderOptionsKpmgUS: Filter[] = [
  {
    name: 'Relevance',
    key: '',
    value: '',
    image: '',
    isDefaultFilter: true,
    sort: 1
  },

  {
    name: 'Date (Newest-Oldest)',
    key: 'dated',
    value: 'DateDesc',
    image: '',
    sort: 2
  },
  {
    name: 'Date (Oldest-Newest)',
    key: 'datea',
    value: 'DateAsc',
    image: '',
    sort: 3
  }
]

const categoryOptionsKpmgUs: Filter[] = [
  {
    name: 'All Categories',
    key: 'all',
    value: 'all',
    image: '',
    isDefaultFilter: true
  }
]

const lineOfBusinessOptionsKpmgUs: Filter[] = [
  {
    name: 'Advisory',
    key: 'advisory',
    value: 'Advisory'
  },
  {
    name: 'Tax',
    key: 'tax',
    value: 'Tax'
  },
  {
    name: 'Audit',
    key: 'audit',
    value: 'Audit'
  }
]

const topicOptionsKpmgUS: Filter[] = [
  {
    name: 'Business Operations',
    key: 'businessoperations',
    value: 'Business Operations'
  },
  {
    name: 'Risk, Regulation, and Compliance',
    key: 'riskregulationandcompliance',
    value: 'Risk, Regulation, and Compliance'
  },
  {
    name: 'Technology',
    key: 'technology',
    value: 'Technology'
  },
  {
    name: 'Transactions',
    key: 'transactions',
    value: 'Transactions'
  },
  {
    name: 'Economy',
    key: 'economy',
    value: 'Economy'
  },
  {
    name: 'ESG',
    key: 'esg',
    value: 'ESG'
  },
  {
    name: 'Innovation',
    key: 'innovation',
    value: 'Innovation'
  },
  {
    name: 'People and Workforce',
    key: 'peopleandworkforce',
    value: 'People and Workforce'
  },
  {
    name: 'Supply Chain',
    key: 'supplychain',
    value: 'Supply Chain'
  }
]

export const kpmgus: FilterCategory[] = [
  {
    name: 'Category',
    key: 'categoryus',
    value: 'categoryus',
    options: categoryOptionsKpmgUs,
    multiselect: true,
    optionsKey: 'categoryus'
  },
  {
    name: 'LineOfBusiness',
    key: 'lineofbusinessus',
    value: 'lineofbusinessus',
    options: lineOfBusinessOptionsKpmgUs,
    multiselect: true
  },
  {
    name: 'Topic',
    key: 'topicus',
    value: 'topicus',
    options: topicOptionsKpmgUS,
    multiselect: true
  },
  {
    name: 'Last Modified',
    key: 'lastmodified',
    value: 'lastmodified',
    options: timeRangeFilter,
    noSorting: true
  },
  {
    name: 'Relevance',
    key: 'orderBy',
    value: 'orderBy',
    options: orderOptionsKpmgUS
  }
]

export const chat: FilterCategory[] = []
