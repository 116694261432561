import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesVideoResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    card: {
      maxWidth: 345,
      boxShadow: 'none',
      border: 'solid 1px #dfe1e5',
      borderRadius: '4px',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        maxWidth: '100%',
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px'
      }
    },
    link: {
      textDecoration: 'none'
    },
    media: {
      height: 110,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#424242',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        height: 100
      }
    },
    duration: {
      color: '#ffffff',
      background: '#00000060',
      padding: 2,
      borderRadius: 4,
      position: 'relative',
      width: 30,
      bottom: 20,
      right: 20
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: 120,
      paddingBottom: '5px',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        height: 100
      }
    },
    spacer: {
      flex: 1
    },
    title: {
      fontSize: '16px',
      width: '100%',
      [`${theme.breakpoints.down(769)} and (orientation: portrait)`]: {
        fontSize: '16px'
      }
    },
    subtitle: {
      fontStyle: 'italic'
    },
    icon: {
      height: 44,
      width: 44,
      color: '#ffffff',
      opacity: '0.9'
    },
    isVisitedResult: {
      color: '#609',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#609'
      }
    },
    footerTable: { display: 'table', width: '100%' },
    footerTableRow: { display: 'table-row', width: '100%' },
    footerTableCell: {
      display: 'table-cell',
      width: '50%',
      verticalAlign: 'top'
    },
    downloadIcon: {
      float: 'right',
      color: 'LinkText',
      width: '0.75em',
      height: '0.75em'
    },
    loadingSpinner: {
      float: 'right',
      color: 'LinkText'
    }
  }))
}
