import React, { useEffect, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  ClickAwayListener,
  Grid,
  Divider,
  Typography,
  ListItemSecondaryAction,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined'
import { useIntl } from 'react-intl'
import { trackException } from 'utils/tracking'
import { getStylesDatasource } from 'styles/contents/Datasource'
import { withStyles } from '@mui/styles'
import { IDeviceSetting } from 'utils/deviceSettings'
import HighlightOff from '@mui/icons-material/HighlightOff'
import Article from '@mui/icons-material/Article'
import AutoAwesomeMosaic from '@mui/icons-material/AutoAwesomeMosaic'

export interface DatasourceProps {
  id: any
  index: number
  icon: any
  name: string
  enabled: boolean
  description: string
  setEnabled: (enabled: boolean, index: number) => void
  move: (dragIndex: number, hoverIndex: number) => void
  isLast?: boolean
  healthStatus?: string
  deviceSettings: IDeviceSetting
  notDisableable?: boolean
  widgetEnabled: boolean
  widgetKey: string | string[] | undefined
  setWidgetEnabled: (
    enabled: boolean,
    key: string | string[] | undefined
  ) => void
}

export type datasourceEnabledState = 'enabled' | 'disabled' | 'widget'

export default function Datasource(props: DatasourceProps): JSX.Element {
  const intl = useIntl()
  const classes = getStylesDatasource()
  const {
    id,
    index,
    icon,
    name,
    enabled,
    description,
    setEnabled,
    move,
    deviceSettings,
    healthStatus,
    notDisableable,
    widgetEnabled,
    widgetKey,
    setWidgetEnabled
  } = props

  const [open, setOpen] = useState(false)
  const [datasourceEnabled, setDatasourceEnabled] =
    useState<datasourceEnabledState>(
      enabled ? (widgetEnabled ? 'widget' : 'enabled') : 'disabled'
    )
  const [showTooltips, setShowTooltips] = useState({
    dsOff: false,
    widgetOff: false,
    allOn: false
  })
  const blockFocusEvent = useRef(false)

  useEffect(() => {
    if (datasourceEnabled === 'widget') {
      setEnabled(true, index)
      setWidgetEnabled(true, widgetKey)
    } else if (datasourceEnabled === 'enabled') {
      setEnabled(true, index)
      setWidgetEnabled(false, widgetKey)
    } else if (datasourceEnabled === 'disabled') {
      setEnabled(false, index)
      setWidgetEnabled(false, widgetKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasourceEnabled])

  useEffect(() => {
    if (enabled && widgetEnabled && datasourceEnabled !== 'widget') {
      setDatasourceEnabled('widget')
    } else if (enabled && !widgetEnabled && datasourceEnabled !== 'enabled') {
      setDatasourceEnabled('enabled')
    } else if (!enabled && datasourceEnabled !== 'disabled') {
      setDatasourceEnabled('disabled')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, widgetEnabled])

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop({
    accept: 'datasource',
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return
      }
      try {
        const dragIndex = item.index
        const hoverIndex = index

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        // Determine mouse position
        const clientOffset = monitor.getClientOffset()

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        // Time to actually perform the action
        move(dragIndex, hoverIndex)

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      } catch (error) {
        trackException('Error in useDrop in Datasource.tsx', error)
        return
      }
    }
  })

  const [{ isDragging }, drag, preview]: any = useDrag({
    type: 'datasource',
    item: () => ({ id, index: index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    previewOptions: {
      offsetX: 28 // this preview offset should not be necessary but without it, the preview on mobile is centering on the cursor for some reason
    }
  })

  drag(drop(ref))

  const listClass = classes.listItem
  const dragItemWithOver = `${classes.dragItem}`

  const opacity = isDragging ? 0 : 1

  const TooltipDatasource = withStyles({
    tooltip: {
      maxWidth: '200px',
      fontSize: '12px'
    }
  })(Tooltip)

  return (
    <div ref={preview} style={{ opacity }}>
      <ListItem className={listClass}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={3} sm={2} className={classes.datasourceActions}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={6}>
                <ListItemIcon ref={ref} className={dragItemWithOver}>
                  <DragIndicatorIcon />
                </ListItemIcon>
              </Grid>
              <Grid item xs={3}>
                <ListItemIcon className={dragItemWithOver}>
                  {React.isValidElement(icon) ? icon : ''}
                </ListItemIcon>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={3} className={classes.datasourceName}>
            <ListItemText
              className={dragItemWithOver}
              primary={intl.formatMessage({
                id: name.toLowerCase().replace(' ', '_'),
                defaultMessage: name
              })}
            />
          </Grid>
          {healthStatus === 'disabled' && (
            <Typography variant="body2" className={classes.healthErrorText}>
              temporarily not available
            </Typography>
          )}
          <ListItemSecondaryAction className={classes.listAction}>
            {
              <TooltipDatasource
                enterTouchDelay={300}
                disableFocusListener
                placement="left"
                arrow
                title={
                  healthStatus !== undefined
                    ? intl.formatMessage({
                        id: 'health_status_' + healthStatus,
                        defaultMessage:
                          healthStatus === 'disabled'
                            ? 'The data source is currently unavailable. Please try again later.'
                            : healthStatus === 'unstable'
                              ? 'The data source seems unstable at this moment. Please expect increased response times and/or limited availability.'
                              : healthStatus === 'healthy'
                                ? 'The data source is up and running.'
                                : ''
                      })
                    : ''
                }
              >
                <ListItemIcon className={classes.descriptionItem}>
                  {healthStatus === 'disabled' && (
                    <ErrorOutlineIcon className={classes.healthErrorIcon} />
                  )}
                  {healthStatus === 'unstable' && (
                    <ReportProblemOutlined
                      className={classes.healthUnstableIcon}
                    />
                  )}
                  {healthStatus === 'healthy' && (
                    <CheckCircleOutlinedIcon
                      className={classes.healthSucessIcon}
                    />
                  )}
                </ListItemIcon>
              </TooltipDatasource>
            }
            {deviceSettings.renderMobile ? (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <TooltipDatasource
                  enterTouchDelay={300}
                  disableFocusListener
                  placement="left"
                  arrow
                  title={intl.formatMessage({
                    id: description,
                    defaultMessage: 'Extended Data Source description'
                  })}
                  open={open}
                  onClose={handleTooltipClose}
                >
                  <ListItemIcon className={classes.descriptionItem}>
                    <HelpOutlineOutlinedIcon onClick={handleTooltipOpen} />
                  </ListItemIcon>
                </TooltipDatasource>
              </ClickAwayListener>
            ) : (
              <TooltipDatasource
                enterTouchDelay={300}
                disableFocusListener
                placement="left"
                arrow
                title={intl.formatMessage({
                  id: description,
                  defaultMessage: 'Extended Data Source description'
                })}
              >
                <ListItemIcon className={classes.descriptionItem}>
                  <HelpOutlineOutlinedIcon />
                </ListItemIcon>
              </TooltipDatasource>
            )}

            <ToggleButtonGroup
              disabled={notDisableable}
              className={classes.gridSwitchContainer}
              value={datasourceEnabled}
              exclusive={true}
              onChange={(event, value) => {
                if (value) {
                  setDatasourceEnabled(value as datasourceEnabledState)
                }
              }}
            >
              <ToggleButton
                value="disabled"
                aria-label="Disabled"
                className={classes.gridSwitchButton}
                onFocus={() => {
                  setTimeout(() => {
                    if (!blockFocusEvent.current) {
                      setShowTooltips({
                        dsOff: true,
                        widgetOff: false,
                        allOn: false
                      })
                    } else {
                      blockFocusEvent.current = false
                    }
                  }, 200)
                }}
                onBlur={() => {
                  if (showTooltips.dsOff) {
                    setShowTooltips({
                      dsOff: false,
                      widgetOff: false,
                      allOn: false
                    })
                  }
                }}
                onMouseDown={() => {
                  blockFocusEvent.current = true
                }}
              >
                <TooltipDatasource
                  enterTouchDelay={300}
                  disableFocusListener
                  placement="top"
                  arrow
                  title={intl.formatMessage({
                    id: 'datasource_off',
                    defaultMessage: 'Turn data source off'
                  })}
                  {...(showTooltips.dsOff ? { open: true } : {})}
                >
                  <HighlightOff />
                </TooltipDatasource>
              </ToggleButton>
              <ToggleButton
                value="enabled"
                aria-label="Enabled"
                className={classes.gridSwitchButton}
                onFocus={() => {
                  setTimeout(() => {
                    if (!blockFocusEvent.current) {
                      setShowTooltips({
                        dsOff: false,
                        widgetOff: true,
                        allOn: false
                      })
                    } else {
                      blockFocusEvent.current = false
                    }
                  }, 200)
                }}
                onBlur={() => {
                  if (showTooltips.widgetOff) {
                    setShowTooltips({
                      dsOff: false,
                      widgetOff: false,
                      allOn: false
                    })
                  }
                }}
                onMouseDown={() => {
                  blockFocusEvent.current = true
                }}
              >
                <TooltipDatasource
                  enterTouchDelay={300}
                  disableFocusListener
                  placement="top"
                  arrow
                  title={intl.formatMessage({
                    id: 'datasource_vertical_only',
                    defaultMessage: 'Show search vertical only'
                  })}
                  {...(showTooltips.widgetOff ? { open: true } : {})}
                >
                  <Article />
                </TooltipDatasource>
              </ToggleButton>
              <ToggleButton
                disabled={!widgetKey}
                value="widget"
                aria-label="Widgets"
                className={classes.gridSwitchButton}
                onFocus={() => {
                  setTimeout(() => {
                    if (!blockFocusEvent.current) {
                      setShowTooltips({
                        dsOff: false,
                        widgetOff: false,
                        allOn: true
                      })
                    } else {
                      blockFocusEvent.current = false
                    }
                  }, 200)
                }}
                onBlur={() => {
                  if (showTooltips.allOn) {
                    setShowTooltips({
                      dsOff: false,
                      widgetOff: false,
                      allOn: false
                    })
                  }
                }}
                onMouseDown={() => {
                  blockFocusEvent.current = true
                }}
              >
                <TooltipDatasource
                  enterTouchDelay={300}
                  disableFocusListener
                  placement="top"
                  arrow
                  title={intl.formatMessage({
                    id: 'datasource_vertical_and_widgets',
                    defaultMessage: 'Show search vertical and widgets'
                  })}
                  {...(showTooltips.allOn ? { open: true } : {})}
                >
                  <AutoAwesomeMosaic />
                </TooltipDatasource>
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItemSecondaryAction>
        </Grid>
      </ListItem>
      {!props.isLast && <Divider />}
    </div>
  )
}
